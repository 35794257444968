import * as React from 'react';

import DataTable from 'react-data-table-component';
import { Button, TextField, Snackbar } from '@material-ui/core';
import matchSorter from 'match-sorter';
import { TEventTarget } from 'types';
import { useHistory } from 'react-router-dom';
import {milisecondDate} from 'utils/utils'
import MuiAlert from '@material-ui/lab/Alert';
import { listAllCotizaciones} from 'services/AdminUsersControlService';

const SummaryOpportunities = () => {
  const history = useHistory();

  const [userCotizaciones, setUserCotizaciones] = React.useState<IUserCotizaciones[] | null>(null);
  const [filteredOpportunities, setFilteredOpportunities] = React.useState<string>('');
  const [loading, setLoading] = React.useState<boolean>(false);
  const [openSnack, setOpenSnack] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>('');


  // filter items according the data
  const filteredItems =
    userCotizaciones !== null
      ? matchSorter(userCotizaciones, filteredOpportunities, {
          keys: ['nDocument', 'firstName', 'lastName', 'licenseVehicle', 'createdAt', 'consecutiveTotalNumber', 'quoteType'],
        })
      : [];
  // handler filter data table
  const onFilterSearch = (e: TEventTarget) => setFilteredOpportunities(e.target.value);

  const resumeCot = (uuid: any, type: any) => {
    switch (type) {
      case 'HogarVidaDeudores':
        history.push({
          pathname: `/app/cotizador/hogar/resumen/${uuid}`,
        });
        break; 
      case 'Auto':
        history.push({
          pathname: `/app/cotizador/autos/resumen/${uuid}`,
        });
        break; 
      case 'Motos':
        history.push({
          pathname: `/app/cotizador/motos/resumen/${uuid}`,
        });
        break;
      case 'Auto_P':
        history.push(({
          pathname: `/app/cotizador/pesados/resumen/${uuid}`
        }))
        break;
    }
  };
  
  // on Error
  const onError = (error: string) => {
    setOpenSnack(true);
    setErrorMessage(error);
  };

  // launch snackBar
  const onCloseSnackBar = () => {
    setOpenSnack(false);
  };

  const columnsCotizaciones = [
    {
      name: 'Número de cotización',
      selector: (row: IUserCotizaciones) => row.consecutiveTotalNumber,
      sortable: true,
    },
    {
      name: 'Fecha',
      selector: (row: IUserCotizaciones) => milisecondDate(row.createdAt),
      sortable: true,
    },
    {
      name: 'Documento',
      selector: (row: IUserCotizaciones) => row.nDocument,
      sortable: true,
    },
    {
      name: 'Nombre del asegurado',
      selector: (row: IUserCotizaciones) => row.firstName,
      sortable: true,
    },
    {
      name: 'Apellido del asegurado',
      selector: (row: IUserCotizaciones) => row.lastName,
      sortable: true,
    },
    {
      name: 'Placa',
      selector: (row: IUserCotizaciones) => row.licenseVehicle,
      sortable: true,
    },
    {
      name: 'Tipo de cotizacion',
      selector: (row: IUserCotizaciones) => row.quoteType,
      sortable: true,
    },
    {
      name: 'Detalles Cotizacion',
      selector: (row: IUserCotizaciones) => (
        <Button
          variant="contained"
          size="small"
          color="primary"
          onClick={() => resumeCot(row.uuid, row.quoteType)}>
          Detalle
        </Button>
      ), 
    },
  ];

  React.useEffect(() => {
    document.title = 'Resumen de oportunidades';
    setLoading(true);

    listAllCotizaciones(onError).then((response) => {
      setUserCotizaciones(response);
      setLoading(false);
    });
  }, []);

  return (
    <React.Fragment>
      <DataTable
        title="Resumen de oportunidades"
        columns={columnsCotizaciones}
        progressPending={loading}
        paginationComponentOptions={{
          rowsPerPageText: 'Filas por página:',
          rangeSeparatorText: 'de',
        }}
        noDataComponent="No se han encontrado registros"
        data={filteredItems}
        defaultSortField="year"
        pagination={true}
        highlightOnHover
        subHeader={true}
        subHeaderComponent={
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <TextField
              id="outlined-basic"
              label="Buscar"
              variant="outlined"
              size="small"
              style={{ margin: '5px' }}
              onChange={onFilterSearch}
            />
          </div>
        }
        fixedHeaderScrollHeight="700px"
      />
      <Snackbar open={openSnack} autoHideDuration={6000} onClose={onCloseSnackBar}>
        <MuiAlert elevation={6} variant="filled" onClose={onCloseSnackBar} severity="error">
          {errorMessage}
        </MuiAlert>
      </Snackbar>
    </React.Fragment>
  );
};

export default SummaryOpportunities;