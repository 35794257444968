/**
 * Pixel for open the drawer
 */
export const drawerWidth = 300;

/**
 * Regex - validates a Colombian License plate car
 */
export const LICENSE_PLATE_VALIDATION = /([a-zA-Z]{3}[0-9]{3}|[SR][0-9]{5})/;

/**
 * Regex - validates a Colombian License plate moto
 */
export const LICENSE_PLATE_VALIDATION_MOTO = /([a-zA-Z]{3}[0-9]{2}[a-zA-Z])/;

/**
 * Link download fasecolda
 */
export const FASECOLDA_DOWNLOAD_LINK =
  'https://guiadevalores.fasecolda.com/ConsultaExplorador/Default.aspx';

/**
 * Regex - calendar validation
 * Formats admits:  dd/mm/yyyy, dd-mm-yyyy or dd.mm.yyyy.
 */
export const CALENDAR_DATE_VALIDATION = /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/;
