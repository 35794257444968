import * as React from 'react';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
  Container,
  LinearProgress,
  Box,
  Snackbar,
  Link,
} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import { createStyles, withStyles, Theme } from '@material-ui/core/styles';
import { useStyles } from './styles';
import { CloudUpload } from '@material-ui/icons';
import { TEventTarget } from 'types';
import { getUrlFasecoldaForUpload, uploadFasecolda } from 'services/UploadFasecoldaService';
import clsx from 'clsx';
import { FASECOLDA_DOWNLOAD_LINK } from 'constants/index';

const BorderLinearProgress = withStyles((theme: Theme) =>
  createStyles({
    root: {
      height: 10,
      borderRadius: 5,
    },
    colorPrimary: {
      backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
    },
    bar: {
      borderRadius: 5,
      backgroundColor: '#1a90ff',
    },
  })
)(LinearProgress);

type TSnackSeverity = 'error' | 'success' | 'info' | 'warning' | undefined;

const UploadFasecolda = () => {
  const classes = useStyles();

  const fileInputRef = React.useRef<any>();

  const [fasecoldaFile, setFasecoldaFile] = React.useState<File | null | undefined>();
  const [uploading, setUploading] = React.useState<boolean>(false);
  const [successFullUploaded, setSuccessFullUploaded] = React.useState<boolean>(false);
  const [progressUpload, setProgressUpload] = React.useState<number>(0);
  const [hightlight, setHightlight] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [openSnack, setOpenSnack] = React.useState<boolean>(false);
  const [snackSeverity, setSnackSeverity] = React.useState<string>('');

  // on Error
  const onError = (error: string) => {
    setOpenSnack(true);
    setErrorMessage(error);
    setSnackSeverity('error');
  };

  // warning messages
  // TODO: Use fasecolda to remember upload file
  // const onWarning = (warn: string) => {
  //   setOpenSnack(true);
  //   setErrorMessage(warn);
  //   setSnackSeverity('warning');
  // };

  const getSnackSeverity = React.useMemo((): TSnackSeverity => {
    if (snackSeverity === 'error') {
      return 'error';
    }
    return 'warning';
  }, [snackSeverity]);

  // launch snackBar
  const onCloseSnackBar = () => {
    setOpenSnack(false);
  };

  const onFileDialog = () => {
    if (uploading || successFullUploaded) return;

    if (fileInputRef.current !== undefined) {
      fileInputRef.current.click();
    }
  };

  const onDragOver = (event: any) => {
    event.preventDefault();
    if (uploading || successFullUploaded) return;

    setHightlight(true);
  };

  const onDragLeave = () => {
    setHightlight(false);
  };

  const onDrop = (e: any) => {
    if (uploading || successFullUploaded) return;

    if (e.dataTransfer.files !== null) {
      e.preventDefault();
      if (e.dataTransfer.files[0].type === 'text/plain') {
        setFasecoldaFile(e.dataTransfer.files[0]);
        setUploading(true);
      } else {
        onError('Archivo no permitido, debe ser formato txt.');
      }
      setHightlight(false);
    }
  };

  const onFileAdded = (e: TEventTarget) => {
    if (uploading || successFullUploaded) return;

    if (e.target.files !== null) {
      if (e.target.files[0].type === 'text/plain') {
        setFasecoldaFile(e.target.files[0]);
        setUploading(true);
      } else {
        onError('Archivo no permitido, debe ser formato txt.');
      }
    }
  };

  const onUploadFiles = async () => {
    setUploading(true);
    setProgressUpload(0);

    try {
      const getUrl = await getUrlFasecoldaForUpload();

      const config = {
        onUploadProgress: (progressEvent: ProgressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setProgressUpload(percentCompleted);
        },
        headers: {},
      };
      uploadFasecolda(getUrl.message, config, fasecoldaFile).then((response) => {
        if (response.statusText !== 'OK') {
          onError('Archivo no permitido, debe ser formato txt.');
        }
      });

      setSuccessFullUploaded(true);
      setUploading(false);
    } catch (err) {
      onError(
        'Ha ocurrido un error al cargar el archivo fasecolda, por favor intentar  más tarde.'
      );
      setSuccessFullUploaded(true);
      setUploading(false);
    }
  };

  const onClearFiles = () => {
    setFasecoldaFile(null);
    setSuccessFullUploaded(false);
  };

  React.useEffect(() => {
    document.title = 'Actualizar código fasecolda';
  }, []);

  return (
    <Container className={classes.cardGrid} maxWidth="md">
      <Typography variant="h4" gutterBottom>
        Subir archivo fasecolda
      </Typography>
      <Typography variant="caption" display="block" gutterBottom>
        El archivo debe tener un formato de texto (Guia_TxtPipe).
      </Typography>
      <Link target="_blank" rel="noopener" href={FASECOLDA_DOWNLOAD_LINK}>
        Descargar archivo Fasecolda actualizado.
      </Link>
      <Card className={classes.root}>
        <CardContent className={classes.content}>
          <Box
            className={clsx(classes.dropzone, hightlight ? classes.highlight : null)}
            style={{ cursor: uploading || successFullUploaded ? 'default' : 'pointer' }}
            onDragOver={onDragOver}
            onDragLeave={onDragLeave}
            onDrop={onDrop}
            onClick={onFileDialog}>
            <input
              ref={fileInputRef}
              type="file"
              className={classes.fileInput}
              onChange={onFileAdded}
            />
            <CloudUpload style={{ fontSize: 64, opacity: 0.3 }} />
            <Typography className={classes.pos} color="textSecondary">
              Cargar Archivo
            </Typography>
          </Box>
          <Box className={classes.files}>
            <Box className={classes.rowFile}>
              <Typography variant="body2" gutterBottom className={classes.fileName}>
                {fasecoldaFile?.name}
              </Typography>
              {uploading ||
                (successFullUploaded && (
                  <Box display="flex" alignItems="center">
                    <Box width="100%" mr={1}>
                      <BorderLinearProgress variant="determinate" value={progressUpload} />
                    </Box>
                    <Box minWidth={35}>
                      <Typography variant="body2" color="textSecondary">{`${Math.round(
                        progressUpload
                      )}%`}</Typography>
                    </Box>
                  </Box>
                ))}
            </Box>
          </Box>
        </CardContent>
        <CardActions className={classes.cardActions}>
          {successFullUploaded ? (
            <Button variant="contained" color="primary" onClick={onClearFiles}>
              Borrar Lista
            </Button>
          ) : (
            <Button
              disabled={!uploading}
              variant="contained"
              color="primary"
              onClick={onUploadFiles}>
              Publicar Archivo
            </Button>
          )}
        </CardActions>
      </Card>
      <Snackbar open={openSnack} autoHideDuration={6000} onClose={onCloseSnackBar}>
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={onCloseSnackBar}
          severity={getSnackSeverity}>
          {errorMessage}
        </MuiAlert>
      </Snackbar>
    </Container>
  );
};

export default UploadFasecolda;
