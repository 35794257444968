/**
 * Insures types
 */
export const insures = [
  { value: 'SBS_HD', label: 'SBS_HD' },
  { value: 'MAPFRE_HD', label: 'MAPFRE_HD' },
  { value: 'LIBERTY_HD', label: 'LIBERTY_HD'},
  { value: 'ESTADO_HD', label: 'ESTADO_HD'},
  // { value: 'SURA_HD', label: 'SURA_HD'},
  { value: 'SURA_HD_N', label: 'SURA_HD_N'},
  // { value: 'SURA_VD', label: 'SURA_VD'},
  { value: 'AXA_VD', label: 'AXA_VD'},
  { value: 'BOLIVAR_VD', label: 'BOLIVAR_VD'},
];

/**
 * Address Type
 */
export const addressType = [
  { value: 'AUTOPISTA', label: 'Autopista' },
  { value: 'AVENIDA', label: 'Avenida' },
  { value: 'CALLE', label: 'Calle' },
  { value: 'CARRERA', label: 'Carrera' },
  { value: 'CIRCULAR', label: 'Circular' },
  { value: 'DIAGONAL', label: 'Diagonal' },
  { value: 'TRANSVERSAL', label: 'Transversal' },
];

/**
 * Address DType
 */
export const addressDType = [
  { value: 'NORTE', label: 'Norte' },
  { value: 'SUR', label: 'Sur' },
  { value: 'ESTE', label: 'Este' },
  { value: 'OESTE', label: 'Oeste' },
];

/**
 * Address DType
 */
export const addressCType = [
  { value: 'NORTE', label: 'Norte' },
  { value: 'SUR', label: 'Sur' },
  { value: 'ESTE', label: 'Este' },
  { value: 'OESTE', label: 'Oeste' },
];

/**
 * Edifice Type
 */
export const dwellingType = [
  { value: 'ciudad', label: 'Urbana' },
  { value: 'rural', label: 'Rural' },
];

/**
 * Edifice Class
 */
export const classEdifice = [
  { value: 'apto', label: 'Apartamento' },
  { value: 'casa', label: 'Casa' },
  { value: 'finca', label: 'Finca / Casa Campestre' },
  { value: 'local', label: 'Local Comercial' },
  { value: 'bodega', label: 'Bodega' },
];

/**
 * Quote Type
 */
export const quoteTypeData = [
  { value: 'hogar', label: 'Hogar' },
  { value: 'vida', label: 'Vida' },
  { value: 'hogarvida', label: 'HogarVida' },
];

/**
 * Document Type
 */
export const documentType = [
  { value: 'cc', label: 'Cédula de Ciudadania' },
  { value: 'ce', label: 'Cédula de Extranjeria' },
  { value: 'nuip', label: 'NUIP' },
  { value: 'ti', label: 'Tarjeta de Identidad' },
];

/**
 * Occupation data
 */
export const occupation = [
  { value: 'empleadoAsalariado', label: 'Empleado/Asalariado' },
  { value: 'independienteComerciante', label: 'Independiente/Comerciante/Socio Propietario' },
  { value: 'estudiante', label: 'Estudiante' },
  { value: 'amaDeCasa', label: 'Ama de Casa' },
  { value: 'pensionado', label: 'Pensionado/a' },
  { value: 'rentista', label: 'Rentista/Inversionista' },
];

/**
* Insures types
*/
export const ages = [
 { value: '2021', label: '2021' },
 { value: '2020', label: '2020' },
 { value: '2019', label: '2019'},
 { value: '2018', label: '2018'},
 { value: '2017', label: '2017'},
 { value: '2016', label: '2016'},
 { value: '2015', label: '2015'},
 { value: '2014', label: '2014'},
 { value: '2013', label: '2013'},
 { value: '2012', label: '2012'},
 { value: '2011', label: '2011'},
 { value: '2010', label: '2010'},
 { value: '2009', label: '2009'},
 { value: '2008', label: '2008'},
 { value: '2007', label: '2007'},
 { value: '2006', label: '2006'},
 { value: '2005', label: '2005'},
 { value: '2004', label: '2004'},
 { value: '2003', label: '2003'},
 { value: '2002', label: '2002'},
 { value: '2001', label: '2001'},
 { value: '2000', label: '2000'},
 { value: '1999', label: '1999'},
 { value: '1998', label: '1998'},
 { value: '1997', label: '1997'},
 { value: '1996', label: '1996'},
 { value: '1995', label: '1995'},
 { value: '1994', label: '1994'},
 { value: '1993', label: '1993'},
 { value: '1992', label: '1992'},
 { value: '1991', label: '1991'},
 { value: '1990', label: '1990'},
 { value: '1989', label: '1989'},
 { value: '1988', label: '1988'},
 { value: '1987', label: '1987'},
 { value: '1986', label: '1986'},
 { value: '1985', label: '1985'},
 { value: '1984', label: '1984'},
 { value: '1983', label: '1983'},
 { value: '1982', label: '1982'},
 { value: '1981', label: '1981'},
 { value: '1980', label: '1980'},
 { value: '1979', label: '1979'},
];

/**
 * Default values for react hook form
 */
export const defaultValues: any = {
  selectedInsurers: insures,
  gender: '',
  firstName: '',
  lastName: '',
  fBorn: '',
  civilState: 'separado',
  documentType: 'cc',
  nDocument: '',
  occupation: 'empleadoAsalariado',
  cityLive: '',
  cityHome: '',
  valueInsured: '',
  dwellingType: '',
  dwellingCategory: '',
  ageBuilding: '',
  nPets: '',
  valueDebtLive: '',
  floorLiveDwells: '',
  tipoVia: '',
  orientacionPrincipal: '',
  orientacionVia: '',
};
