import * as React from 'react';
import { Typography, Container, Grid, Paper, Button } from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';

import { useStyles } from './styles';
import ApplicantDetails from 'components/QuotationResumeHome/ApplicantDetails/ApplicantDetails';
import QuoteResult from 'components/QuotationResumeHome/QuoteResult/QuoteResult';
import { getQuotationResume, getInsureStatus, resetBotQuotation } from 'services/QuotationServiceHome';
import { formatDate, createDataList as createQuotationData } from 'utils/utils';
import { cities } from 'pages/HomeQuotation/cities';
import { insures } from '../HomeQuotation/resources';

type TNewQuotationValue = { [Key in symbol]?: object };

const QuotationResume = () => {
  // classes
  const classes = useStyles();

  // states
  const [quotationValue, setQuotation] = React.useState<IQuoteResponseHome | null>(null);
  const [userData, setUserData] = React.useState<IApplicantTableData[]>([]);

  // navigation
  const history = useHistory();
  const { id } = useParams<{ id: string }>();

  const routerNavigationToCarsQuotation = (newQuotationValue?: TNewQuotationValue) => {
    if (newQuotationValue) {
      history.push({
        pathname: '/app/cotizador/hogar-vida',
        state: {
          quotation: newQuotationValue,
        },
      });
    } else {
      history.push({
        pathname: '/app/cotizador/hogar-vida',
      });
    }
  };

  // handlers
  const onNavigationToReQuote = () => {
    // const selectedInsurerSelect = quotationValue?.selectedInsurers.map((quote) => ({
    //   value: quote,
    //   label: quote,
    // }));

    const cityCirculationSearch = cities.find(
      (city: { value: string; label: string }) => city.value === quotationValue?.cityHome[0]
    );

    const cityCirculationSearchLive = cities.find(
      (city: { value: string; label: string }) => city.value === quotationValue?.cityLive[0]
    );

    const newQuotationValue: TNewQuotationValue = {
      ...quotationValue,
      selectedInsurers: insures,
      cityHome: cityCirculationSearch,
      cityLive: cityCirculationSearchLive,
    };
    routerNavigationToCarsQuotation(newQuotationValue);
  };

  const onNavigationToReQuoteWithEmptyForm = () => {
    routerNavigationToCarsQuotation();
  };

  const responseQuoteService = React.useCallback(async () => {
    const typeQuoteWithId = `${id}/HogarVidaDeudores`;
    const { cotizacion } = await getQuotationResume(typeQuoteWithId);
    const collectionQuotationData = [
      createQuotationData('Nombre', `${cotizacion.firstName} ${cotizacion.lastName}`),
      createQuotationData('No. Documento', `${cotizacion.nDocument}`),
      createQuotationData('Género', cotizacion.gender),
      createQuotationData('Datos Hogar', ''),
      createQuotationData('Direccion Riesgo', cotizacion.addressHome),
      createQuotationData('Ciudad del Riesgo', cotizacion.cityHome.join(', ')),
      createQuotationData('Zona', cotizacion.dwellingType),
      createQuotationData('Tipo de Riesgo', cotizacion.dwellingCategory),
      createQuotationData('No. Pisos del Edificio/Casa', `${cotizacion.nFloors}`),
      createQuotationData('Piso del Apto', `${cotizacion.floorLiveDwells}`),
      createQuotationData('No. Sotanos', `${cotizacion.nBasement}`),
      createQuotationData('Valor Comercial', `${cotizacion.valueDwelling}`),
      createQuotationData('Año Finalización de la Construcción', cotizacion.ageBuilding),
      createQuotationData('No. de Mascotas', `${cotizacion.nPets}`),
      createQuotationData('Datos Vida', ''),
      createQuotationData('Fecha de Nacimiento', cotizacion.fBorn),
      createQuotationData('Ocupación', cotizacion.occupation),
      createQuotationData('Ciudad', cotizacion.cityLive.join(', ')),
      createQuotationData('Saldo de Capital Credito', `${cotizacion.valueDebtLive}`),
    ];

    setUserData(collectionQuotationData);

    setQuotation(cotizacion);
  }, [id]);

  const onInsureStatusData = React.useCallback(
    async (insurerName?: string) => {
      const response = await getInsureStatus(id, insurerName);
      return response;
    },
    [id]
  );

  const onResetBotQuotation = React.useCallback(
    async (insurer?: string) => {
      if (insurer) {
        const response = await resetBotQuotation(id, insurer);

        return response;
      }
    },
    [id]
  );

  React.useEffect(() => {
    responseQuoteService();
  }, [responseQuoteService]);

  React.useEffect(() => {
    document.title = 'Resumen de la cotización';
  }, []);

  return (
    <>
      <Grid item xs={12} md={9} lg={9}>
        <Container className={classes.headerTitle} maxWidth="xl">
          <Typography variant="h4" gutterBottom className={classes.title} display="block">
            Número de Cotización: {quotationValue?.consecutiveTotalNumber}
          </Typography>
          <Typography variant="caption" gutterBottom display="block" className={classes.dateLabel}>
            {formatDate(quotationValue?.createdAt)}
          </Typography>
        </Container>
      </Grid>
      <Grid item xs={12} md={3} lg={3} className={classes.quoteAgain}>
        <Container maxWidth="xl">
          <Button
            color="primary"
            variant="contained"
            className={classes.fullWidthButton}
            onClick={onNavigationToReQuote}>
            Cotizar de nuevo
          </Button>
          <Button
            color="primary"
            variant="contained"
            className={classes.fullWidthButton}
            onClick={onNavigationToReQuoteWithEmptyForm}>
            Nueva cotización
          </Button>
        </Container>
      </Grid>
      <Grid item xs={12} md={7} lg={8}>
        <Paper className={classes.paper}>
          <QuoteResult
            insuresResults={quotationValue}
            onInsureStatusData={onInsureStatusData}
            onResetBotQuotation={onResetBotQuotation}
          />
        </Paper>
      </Grid>
      <Grid item xs={12} md={5} lg={4}>
        <Paper className={classes.paper}>
          <ApplicantDetails tableData={userData} />
        </Paper>
      </Grid>
    </>
  );
};

export default QuotationResume;
