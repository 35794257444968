import * as React from 'react';

import SbsLogo from 'assets/Insures/sbs.png';
import SuraLogo from 'assets/Insures/sura.png';
import MapfreLogo from 'assets/Insures/mapfre.png'
import LibertyLogo from 'assets/Insures/liberty.png'

enum Insurances {
  SBS_MOTOS = 'SBS_MOTOS',
  SURA_MOTOS = 'SURA_MOTOS',
  MAPFRE_MOTOS = 'MAPFRE_MOTOS',
  LIBERTY_MOTOS = 'LIBERTY_MOTOS'

}

const InsuranceLogo: React.FC<IInsuranceLogoProps> = ({ insurance }) => { 
  if (insurance === Insurances.SBS_MOTOS) {
    return <img width={70} height={40} src={SbsLogo} alt="sbs logo" />;
  }
  if (insurance === Insurances.SURA_MOTOS) {
    return <img width={70} height={40} src={SuraLogo} alt="sura logo" />;
  }
  if (insurance === Insurances.MAPFRE_MOTOS) {
    return <img width={70} height={40} src={MapfreLogo} alt="sura logo" />;
  }
  if (insurance === Insurances.LIBERTY_MOTOS) {
    return <img width={70} height={40} src={LibertyLogo} alt="sura logo" />;
  }
  return null;
};

export default InsuranceLogo;
