import * as React from 'react';
import { Grid, Typography } from '@material-ui/core';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { Controller } from 'react-hook-form';

import MaterialTheme from 'theme';
import { useStyles } from './styles';

const QuoteType: React.FC<any> = ({
  insures,
  control,
  // quoteType,
  disabledInsures,
  // disabledQuoteType,
}) => {
  // styles
  const classes = useStyles();

  // animation for multiselect
  const animatedComponents = makeAnimated();

  // Select theme
  const SelectTheme = (theme: any) => ({
    ...theme,
    colors: {
      ...theme.colors,
      text: 'orangered',
      primary: MaterialTheme.palette.primary.main,
    },
  });

  return (
    <>
      <Grid item xs={12} sm={8}>
        <Typography className={classes.label}>Aseguradoras</Typography>
        <Controller
          name="selectedInsurers"
          as={Select}
          placeholder="Seleccionar..."
          closeMenuOnSelect={false}
          components={animatedComponents}
          isMulti
          options={insures}
          isDisabled={disabledInsures}
          theme={SelectTheme}
          control={control}
        />
      </Grid>
      {/* <Grid item xs={12} sm={4}>
        <Typography className={classes.label}>Ramo a Cotizar</Typography>
        <Controller
          name="quoteType"
          as={Select}
          control={control}
          placeholder="Seleccionar..."
          closeMenuOnSelect={true}
          options={quoteType}
          menuColor="red"
          theme={SelectTheme}
          isDisabled={disabledQuoteType}
        />
      </Grid> */}
    </>
  );
};

export default QuoteType;
