import * as React from 'react';
import {
  Avatar,
  Typography,
  TextField,
  Button,
  Grid,
  FormControl,
  Select,
  MenuItem,
} from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { useStyles } from './styles';
import { useForm, Controller } from 'react-hook-form';
import { defaultValues, roleType, branchOfficeTypes } from './resources';
import { BootstrapInput } from 'components/Global/PickerSelect/PickerSelect';
import { signNewUser } from 'services/AdminSignNewUser';

const SignNewUser: React.FC<ISignNewUser> = ({ toggleModal }) => {
  const classes = useStyles();

  // useForm
  const { handleSubmit, control } = useForm({ defaultValues });

  const onSubmitSignNewUser = (newUser: ISignNewUserFormValues) => {
    signNewUser(
      newUser.email,
      newUser.password,
      newUser.name,
      newUser.lastName,
      newUser.role,
      newUser.branchOffice,
      toggleModal
    );
  };

  return (
    <div className={classes.paper}>
      <Avatar className={classes.avatar}>
        <LockOutlinedIcon />
      </Avatar>
      <Typography component="h1" variant="h5">
        Registrar nuevo usuario
      </Typography>
      <form className={classes.form} onSubmit={handleSubmit(onSubmitSignNewUser)}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Controller
              as={TextField}
              name="name"
              label="Nombre"
              variant="outlined"
              autoComplete="fname"
              required
              fullWidth
              id="firstName"
              autoFocus
              control={control}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Controller
              as={TextField}
              variant="outlined"
              required
              fullWidth
              id="lastName"
              label="Apellido"
              name="lastName"
              autoComplete="lname"
              control={control}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <Controller
              as={TextField}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Correo electrónico"
              name="email"
              autoComplete="email"
              autoFocus
              control={control}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <Controller
              as={TextField}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Contraseña"
              type="password"
              id="password"
              autoComplete="current-password"
              control={control}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl className={classes.margin}>
              <Typography className={classes.label}>Tipo de usuario</Typography>
              <Controller
                as={
                  <Select
                    labelId="demo-customized-select-label"
                    id="demo-customized-select"
                    input={<BootstrapInput />}>
                    {roleType.map((menuItem: any, index: any) => (
                      <MenuItem key={index} value={menuItem.value}>
                        {menuItem.label}
                      </MenuItem>
                    ))}
                  </Select>
                }
                control={control}
                name="role"
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl className={classes.margin}>
              <Typography className={classes.label}>Sucursal</Typography>
              <Controller
                as={
                  <Select
                    labelId="demo-customized-select-label"
                    id="demo-customized-select"
                    input={<BootstrapInput />}>
                    {branchOfficeTypes.map((menuItem: any, index: any) => (
                      <MenuItem key={index} value={menuItem.value}>
                        {menuItem.label}
                      </MenuItem>
                    ))}
                  </Select>
                }
                control={control}
                name="branchOffice"
              />
            </FormControl>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}>
            Crear usuario
          </Button>
          <Button fullWidth variant="outlined" className={classes.submit} onClick={toggleModal}>
            Cancelar
          </Button>
        </Grid>
      </form>
    </div>
  );
};

export default SignNewUser;
