import * as React from 'react';

import AxaLogo from 'assets/Insures/axa.jpg';
import BolivarLogo from 'assets/Insures/bolivar.png';
import EquidadLogo from 'assets/Insures/equidad.png';
import EstadoLogo from 'assets/Insures/estado.png';
import HdiLogo from 'assets/Insures/hdi.png';
import SbsLogo from 'assets/Insures/sbs.png';
import SuraLogo from 'assets/Insures/sura.png';
import LibertyLogo from 'assets/Insures/liberty.png';
import ZurichLogo from 'assets/Insures/zurich.png';
import MapfreLogo from 'assets/Insures/mapfre.png';

enum Insurances {
  AXA = 'AXA',
  BOLIVAR_VD = 'BOLIVAR_VD',
  EQUIDAD = 'EQUIDAD',
  ESTADO_HD = 'ESTADO_HD',
  HDI = 'HDI',
  MAPFRE_HD = 'MAPFRE_HD',
  SBS_HD = 'SBS_HD',
  AXA_VD = 'AXA_VD',
  SURA_HD = 'SURA_HD',
  SURA_HD_N = 'SURA_HD_N',
  SURA_VD = 'SURA_VD',
  LIBERTY_HD = 'LIBERTY_HD',
  ZURICH = 'ZURICH',
}

const InsuranceLogo: React.FC<IInsuranceLogoProps> = ({ insurance }) => {
  if (insurance === Insurances.AXA_VD) {
    return <img width={60} height={40} src={AxaLogo} alt="axa logo" />;
  }
  if (insurance === Insurances.BOLIVAR_VD) {
    return <img width={80} height={45} src={BolivarLogo} alt="bolivar logo" />;
  }
  if (insurance === Insurances.EQUIDAD) {
    return <img width={40} height={40} src={EquidadLogo} alt="equidad logo" />;
  }
  if (insurance === Insurances.ZURICH) {
    return <img width={100} height={40} src={ZurichLogo} alt="zurich logo" />;
  }
  if (insurance === Insurances.ESTADO_HD) {
    return <img width={80} height={40} src={EstadoLogo} alt="estado logo" />;
  }
  if (insurance === Insurances.HDI) {
    return <img width={60} height={30} src={HdiLogo} alt="hdi logo" />;
  }
  if (insurance === Insurances.MAPFRE_HD) {
    return <img width={120} height={30} src={MapfreLogo} alt="mapfre logo" />;
  }
  if (insurance === Insurances.SBS_HD) {
    return <img width={70} height={40} src={SbsLogo} alt="sbs logo" />;
  }
  if (insurance === Insurances.SURA_HD) {
    return <img width={70} height={40} src={SuraLogo} alt="sura logo" />;
  }
  if (insurance === Insurances.SURA_HD_N) {
    return <img width={70} height={40} src={SuraLogo} alt="sura logo" />;
  }
  if (insurance === Insurances.SURA_VD) {
    return <img width={70} height={40} src={SuraLogo} alt="sura logo" />;
  }
  if (insurance === Insurances.LIBERTY_HD) {
    return <img width={60} height={40} src={LibertyLogo} alt="liberty logo" />;
  }
  return null;
};

export default InsuranceLogo;
