import * as React from 'react';

import AxaLogo from 'assets/Insures/axa.jpg';
import BolivarLogo from 'assets/Insures/bolivar.png';
import EquidadLogo from 'assets/Insures/equidad.png';
import EstadoLogo from 'assets/Insures/estado.png';
import HdiLogo from 'assets/Insures/hdi.png';
import SbsLogo from 'assets/Insures/sbs.png';
import SuraLogo from 'assets/Insures/sura.png';
import LibertyLogo from 'assets/Insures/liberty.png';
import ZurichLogo from 'assets/Insures/zurich.png';
import MapfreLogo from 'assets/Insures/mapfre.png';
import PrevisoraLogo from 'assets/Insures/previsora.png';
import SolidariaLogo from 'assets/Insures/solidariaLogo.png';

enum Insurances {
  AXA = 'AXA',
  BOLIVAR = 'BOLIVAR',
  EQUIDAD = 'EQUIDAD',
  ESTADO = 'ESTADO',
  HDI = 'HDI',
  MAPFRE = 'MAPFRE',
  SBS = 'SBS',
  SURA = 'SURA',
  LIBERTY = 'LIBERTY',
  ZURICH = 'ZURICH',
  HDI_B = 'HDI_B',
  PREVISORA = 'PREVISORA',
  SOLIDARIA = 'SOLIDARIA',
}

const InsuranceLogo: React.FC<IInsuranceLogoProps> = ({ insurance }) => {
  if (insurance === Insurances.AXA) {
    return <img width={60} height={40} src={AxaLogo} alt="axa logo" />;
  }
  if (insurance === Insurances.BOLIVAR) {
    return <img width={80} height={45} src={BolivarLogo} alt="bolivar logo" />;
  }
  if (insurance === Insurances.EQUIDAD) {
    return <img width={40} height={40} src={EquidadLogo} alt="equidad logo" />;
  }
  if (insurance === Insurances.ZURICH) {
    return <img width={100} height={40} src={ZurichLogo} alt="zurich logo" />;
  }
  if (insurance === Insurances.ESTADO) {
    return <img width={80} height={40} src={EstadoLogo} alt="estado logo" />;
  }
  if (insurance === Insurances.HDI) {
    return <img width={60} height={30} src={HdiLogo} alt="hdi logo" />;
  }
  if (insurance === Insurances.MAPFRE) {
    return <img width={90} height={30} src={MapfreLogo} alt="mapfre logo" />;
  }
  if (insurance === Insurances.SBS) {
    return <img width={70} height={40} src={SbsLogo} alt="sbs logo" />;
  }
  if (insurance === Insurances.SURA) {
    return <img width={70} height={40} src={SuraLogo} alt="sura logo" />;
  }
  if (insurance === Insurances.LIBERTY) {
    return <img width={60} height={40} src={LibertyLogo} alt="liberty logo" />;
  }
  if (insurance === Insurances.HDI_B) {
    return <img width={60} height={40} src={HdiLogo} alt="hdi logo" />;
  }
  if (insurance === Insurances.PREVISORA) {
    return <img width={60} height={40} src={PrevisoraLogo} alt="previsora logo" />;
  }
  if (insurance === Insurances.SOLIDARIA) {
    return <img width={60} height={40} src={SolidariaLogo} alt="solidaria logo" />;
  }
  return null;
};

export default InsuranceLogo;
