/* tslint:disable: jsx-no-lambda */
import * as React from 'react';
import {
  Grid,
  FormControl,
  Typography,
  Select,
  MenuItem,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core';
import { Controller } from 'react-hook-form';
import AsyncSelect from 'react-select/async';

import InputField from 'components/Global/InputLabel/InputLabel';
import { useStyles } from './styles';
import { IUserDataHome } from 'types/HomeQuotation/IUserDataHome';
import { BootstrapInput } from 'components/Global/PickerSelect/PickerSelect';
import { REQUIRED_FIELD } from 'constants/notifyConstants';
import NumberFormat, { NumberFormatValues } from 'react-number-format';
import { INumberFormatMoneyValuePros } from 'types/CarsQuotation/IVehicleInfo';

const NumberFormatPets = (props: INumberFormatMoneyValuePros) => {
  const onChangeValue = (e: NumberFormatValues) => {
    return props.onChange(e.floatValue);
  };

  return (
    <NumberFormat
      customInput={InputField}
      required={props.required ? props.required : false}
      thousandSeparator={true}
      placeholder="0"
      onValueChange={onChangeValue}
      value={props.value}
      label={props.labelContent}
      id={props.idComponent}
      disabled={props.disabled}
    />
  );
};

const NumberFormatMoneyValue = (props: INumberFormatMoneyValuePros) => {
  const onChangeValue = (e: NumberFormatValues) => {
    return props.onChange(e.floatValue);
  };

  return (
    <NumberFormat
      customInput={InputField}
      required={props.required ? props.required : false}
      thousandSeparator={true}
      prefix={'$'}
      placeholder="$0"
      onValueChange={onChangeValue}
      value={props.value}
      label={props.labelContent}
      id={props.idComponent}
      disabled={props.disabled}
    />
  );
};

const UserDataHome: React.FC<IUserDataHome> = ({
  control,
  errors,
  classEdificeItems,
  addressTypeItems,
  addressDTypeItems,
  addressCTypeItems,
  dwellingTypeItems,
  dwellingCategoryDisable,
  loadCitiesOptions,
  handleInputCitiesChange,
  checkAddress,
  checkboxPets,
  agesItems
}) => {
  const classes = useStyles();
  
  const [typeEdifice, setTypeEdifice] = React.useState('');
  const [typeRiesgo, setTypeRiesgo] = React.useState('');
  const [active, setActive] = React.useState(true);

  const handleChangeEdifice = (event: any) => {
    setTypeEdifice(event.target.value);
  };

  const handleChangeRiesgo = (event: any) => {
    if (dwellingCategoryDisable === 'apto') {
      setActive(false)
    } else {
      setActive(true)
    }
    setTypeRiesgo(event.target.value);
  };

  return (
    <>
      <Grid item xs={12} sm={12}>
        <Controller
          render={({ onChange, value }) => (
            <FormControlLabel
              control={
                <Checkbox
                  disabled={true}
                  name="checkedC"
                  color="primary"
                  value={value}
                  onChange={(e) => onChange(e.target.checked)}
                />
              }
              label="¿Conoce la Dirección Exacta del Riesgo?"
            />
          )}
          control={control}
          name="checkBoxAddress"
        />
      </Grid>
      <Grid item xs={12} sm={2}>
        <FormControl className={classes.margin} disabled={!checkAddress}>
          <Typography className={classes.label}></Typography>
          <Controller
            as={
              <Select
              labelId="demo-customized-select-label"
              id="demo-customized-select"
              value={typeEdifice}
              onChange={handleChangeEdifice}
              input={<BootstrapInput />}>
                {addressTypeItems.map((menuItem: any, index: any) => (
                  <MenuItem key={index} value={menuItem.value}>
                    {menuItem.label}
                  </MenuItem>
                ))}
              </Select>
            }
            control={control}
            name="tipoVia"
          />
          <Typography className={classes.labelTwo}>Ej:CALLE</Typography>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={1}>
        <FormControl className={classes.margin}>
          <Controller
            as={InputField }
            name="nViaPrincipal"
            disabled={!checkAddress}
            autoComplete="nViaPrincipal"
            id="nViaPrincipal"
            placeholder="49"
            control={control}
          />
          <Typography className={classes.labelTwo}>49</Typography>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={1}>
        <Controller
          as={InputField }
          name="letraViaPrincipal"
          disabled={!checkAddress}
          autoComplete="letraViaPrincipal"
          id="letraViaPrincipal"
          placeholder="B"
          control={control}
        />
        <Typography className={classes.labelTwo}>B</Typography>
      </Grid>
      <Grid item xs={12} sm={1}>
        <FormControl className={classes.margin} disabled={!checkAddress}>
          <Typography className={classes.label}></Typography>
          <Controller
            as={
              <Select
              labelId="demo-customized-select-label"
              id="demo-customized-select"
              value={typeEdifice}
              onChange={handleChangeEdifice}
              input={<BootstrapInput />}>
                {addressDTypeItems.map((menuItem: any, index: any) => (
                  <MenuItem key={index} value={menuItem.value}>
                    {menuItem.label}
                  </MenuItem>
                ))}
              </Select>
            }
            control={control}
            name="orientacionPrincipal"
          />
          <Typography className={classes.labelTwo}>SUR</Typography>
        </FormControl>
      </Grid>
      <Typography className={classes.labelThree}>#</Typography>
      <Grid item xs={12} sm={1}>
        <Controller
          as={InputField }
          name="nVia"
          disabled={!checkAddress}
          autoComplete="nVia"
          id="nVia"
          placeholder="49"
          control={control}
        />
        <Typography className={classes.labelTwo}>49</Typography>
      </Grid>
      <Grid item xs={12} sm={1}>
        <Controller
          as={InputField }
          name="letraVia"
          disabled={!checkAddress}
          autoComplete="letraVia"
          id="letraVia"
          placeholder="D"
          control={control}
        />
        <Typography className={classes.labelTwo}>D</Typography>
      </Grid>
      <Typography className={classes.labelThree}>-</Typography>
      <Grid item xs={12} sm={1}>
        <FormControl className={classes.margin} disabled={!checkAddress}>
          <Typography className={classes.label}></Typography>
          <Controller
            as={
              <Select
              labelId="demo-customized-select-label"
              id="demo-customized-select"
              value={typeEdifice}
              onChange={handleChangeEdifice}
              input={<BootstrapInput />}>
                {addressCTypeItems.map((menuItem: any, index: any) => (
                  <MenuItem key={index} value={menuItem.value}>
                    {menuItem.label}
                  </MenuItem>
                ))}
              </Select>
            }
            control={control}
            name="orientacionVia"
          />
          <Typography className={classes.labelTwo}>NORTE</Typography>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={1}>
        <Controller
          as={InputField }
          name="n2Via"
          disabled={!checkAddress}
          autoComplete="n2Via"
          id="n2Via"
          placeholder="46"
          control={control}
        />
        <Typography className={classes.labelTwo}>46</Typography>
      </Grid>
      <Grid item xs={12} sm={2}>
        <Controller
          as={InputField }
          name="adicional"
          disabled={!checkAddress}
          autoComplete="adicional"
          id="adicional"
          placeholder="Leyenda Adicional"
          control={control}
        />
        <Typography className={classes.labelTwo}>EDIFICIO PRINCIPAL</Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Controller
          as={InputField }
          name="addressHome"
          disabled={checkAddress}
          label="Direccion Riesgo"
          autoComplete="address"
          id="address"
          control={control}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <FormControl className={classes.margin}>
          <Typography className={classes.label}>Ciudad del Riesgo</Typography>
          <Controller
            as={
              <AsyncSelect
                cacheOptions
                loadOptions={loadCitiesOptions}
                defaultOptions
                onInputChange={handleInputCitiesChange}
                placeholder="Seleccionar..."
              />
            }
            control={control}
            name="cityHome"
            rules={{
              required: REQUIRED_FIELD,
            }}
          />
          {errors.cityHome && errors.cityHome.type === 'required' && (
            <Typography variant="caption" display="block" gutterBottom style={{ color: 'red' }}>
              {errors.cityHome.message}
            </Typography>
          )}
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={3}>
        <FormControl className={classes.margin}>
          <Typography className={classes.label}>Zona</Typography>
          <Controller
            as={
              <Select
                labelId="demo-customized-select-label"
                id="demo-customized-select"
                input={<BootstrapInput />}>
                {dwellingTypeItems.map((menuItem: any, index: any) => (
                  <MenuItem key={index} value={menuItem.value}>
                    {menuItem.label}
                  </MenuItem>
                ))}
              </Select>
            }
            control={control}
            name="dwellingType"
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={3}>
        <FormControl className={classes.margin}>
          <Typography className={classes.label}>Tipo de Riesgo</Typography>
          <Controller
            as={
              <Select
                labelId="demo-customized-select-label"
                id="demo-customized-select"
                value={typeRiesgo}
                onChange={handleChangeRiesgo}
                input={<BootstrapInput />}>
                {classEdificeItems.map((menuItem: any, index: any) => (
                  <MenuItem key={index} value={menuItem.value}>
                    {menuItem.label}
                  </MenuItem>
                ))}
              </Select>
            }
            control={control}
            name="dwellingCategory"
            rules={{
              required: REQUIRED_FIELD,
            }}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={3}>
        <Controller
          as={InputField}
          name="nFloors"
          label="No. Pisos del Edificio / Casa"
          autoComplete="nFloors"
          id="nFloors"
          required
          type="number"
          control={control}
          rules={{
            required: REQUIRED_FIELD,
          }}
        />
        {errors.lastName && errors.lastName.type === 'required' && (
          <Typography variant="caption" display="block" gutterBottom style={{ color: 'red' }}>
            {errors.lastName.message}
          </Typography>
        )}
      </Grid>
      {dwellingCategoryDisable === 'apto' ? (
        <Grid item xs={12} sm={3}>
          <Controller
            as={InputField}
            name="floorLiveDwells"
            label="Piso del Apto"
            autoComplete="floorLiveDwells"
            id="floorLiveDwells"
            placeholder="0"
            type="number"
            control={control}
          />
        </Grid>
      ) : (
        <Typography className={classes.label}></Typography>
      )}
      {dwellingCategoryDisable === 'apto' ? (
        <Grid item xs={12} sm={3}>
          <Controller
            as={InputField}
            name="nBasement"
            label="No. Sotanos"
            autoComplete="nBasement"
            id="nBasement"
            placeholder="0"
            required
            type="number"
            control={control}
          />
        </Grid>
      ) : (
        <Typography className={classes.label}></Typography>
      )}
      <Grid item xs={12} sm={3}>
        <Controller
          render={(props) => (
            <NumberFormatMoneyValue
              labelContent="Valor Comercial"
              idComponent="securedValue"
              {...props}
              disabled={false}
            />
          )}
          control={control}
          name="valueDwelling"
          rules={{
            required: REQUIRED_FIELD,
          }}
        />
        {errors.valueInsured && errors.valueInsured.type === 'required' && (
          <Typography variant="caption" display="block" gutterBottom style={{ color: 'red' }}>
            {errors.valueInsured.message}
          </Typography>
        )}
      </Grid>
      <Grid item xs={12} sm={4}>
        <FormControl className={classes.margin}>
          <Typography className={classes.label}>Año Finalización de la Construcción</Typography>
          <Controller
            as={
              <Select
                labelId="demo-customized-select-label"
                id="demo-customized-select"
                value={typeEdifice}
                onChange={handleChangeEdifice}
                input={<BootstrapInput />}>
                {agesItems.map((menuItem: any, index: any) => (
                  <MenuItem key={index} value={menuItem.value}>
                    {menuItem.label}
                  </MenuItem>
                ))}
              </Select>
            }
            control={control}
            name="ageBuilding"
            rules={{
              required: REQUIRED_FIELD,
            }}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={5}>
        <Controller
          render={({ onChange, value }) => (
            <FormControlLabel
              control={
                <Checkbox
                  name="checkedB"
                  color="primary"
                  value={value}
                  onChange={(e) => onChange(e.target.checked)}
                />
              }
              label="¿Desea Tomar Cobertura Mascotas?"
            />
          )}
          control={control}
          name="checkPets"
        />
        <Typography className={classes.label}>No. de Mascotas</Typography>
        <Controller
          render={(props) => <NumberFormatPets {...props} disabled={!checkboxPets} />}
          control={control}
          name="nPets"
          id="nPets"
        />
      </Grid>
      
      {dwellingCategoryDisable === 'apto' ? (
        <Typography className={classes.label}></Typography>
      ) : (
        <Grid item xs={12} sm={3}>
        </Grid>
      )}
    </>
  );
};

export default UserDataHome;
