import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(-1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: "#43FEFF",
    color: 'white',
    width: 150,
    fontWeight: 'bold'
  },
  loginLoader: {
    marginLeft: theme.spacing(4),
  },
  logotypeIcon: {
    margin: theme.spacing(-4, 1, 4, 1),

    width: 160,
    fill: '#ff0000'
  },
  colorLinkForgotPassword: {
  },
  inputAdornment: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    fontSize: '2rem',
  },
  txtPromo: {
    color: '#2466AE',
    fontSize: '0.8rem',
    fontWeight: 'bold'
  },
  txtForgotPassword: {
    color: '#A0A0A0',
    fontSize: '0.7rem',
    fontWeight: 'bold'
  },
  txtRemember: {
    color: '#A0A0A0',
    fontSize: '0.7rem',
    fontWeight: 'bold'
  }
  ,
  underline: {
    '&:before': {
      borderBottom: 'none', // No mostrar ninguna línea negra en ningún momento
    },
    '&:hover:before': {
      borderBottom: 'none !important', // Eliminar la línea negra en hover también
    },
    '&:hover:not(.Mui-disabled):after': {
      borderBottom: `2px solid ${theme.palette.primary.main} !important`, // Azul en hover
    },
    '&:after': {
      borderBottom: `2px solid ${theme.palette.primary.main}`, // Azul en focus
    },
  },
  root: {
    backgroundColor: '#F6F6F6',
    borderRadius: '5px', // Define el radio de los bordes para que sean redondeados
    '& .MuiFilledInput-root': {
      borderRadius: '5px', // Ajuste adicional para asegurarse de que los bordes inferiores también sean redondeados,
    }
  }
}));
