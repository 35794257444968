import axios from 'axios';
import { Auth } from 'aws-amplify';
import download from 'downloadjs';
import { HttpRequest } from './HttpRequest';

export const actUser= async (
  email: any,
  role: any,
) => {
  try {
    const URL = 'https://api.cotizadorvirtual.co/dev/modificarPerfil';
    const currentSession = await Auth.currentSession();
    const idToken = currentSession.getIdToken().getJwtToken();
    const data = {
      role: role,
      userName: email
    }
    axios.put(URL, data, {
      headers: {
        Authorization: idToken
      },
    })
      .then((res) => {
        window.location.reload();
      })
    } catch (e) {
      throw new Error(e);
    }
};

export const delUser = async (
  email: any,
): Promise<any> => {
  try {
    const URL = 'https://api.cotizadorvirtual.co/dev/eliminarUsuario';
    const currentSession = await Auth.currentSession();
    const idToken = currentSession.getIdToken().getJwtToken();
    axios.delete(URL, {
      headers: {
        Authorization: idToken
      },
      data: {
        userName: email
      }
    })
    
    .then((res) => {
      window.location.reload();
    })
  } catch (e) {
    throw new Error(e);
  }
};

export const statusUser = async (
  email: any,
  status: any
): Promise<any> => {
  try {
    const URL = 'https://api.cotizadorvirtual.co/dev/estadoUsuario';
    const currentSession = await Auth.currentSession();
    const idToken = currentSession.getIdToken().getJwtToken();
    const data = {
      userName: email,
      estado: status
    }
    axios.put(URL, data, {
      headers: {
        Authorization: idToken
      },
    })
      .then((res) => {
        window.location.reload();
      })
    } catch (e) {
      throw new Error(e);
    }
};

export const downloadPdf = async (
  numCot: any,
  nameInsurer: any,
  onError: (text: string) => void
): Promise<any> => {
  try {
    const currentSession = await Auth.currentSession();
    const idToken = currentSession.getIdToken().getJwtToken();
    const URL = `https://api.cotizadorvirtual.co/dev/generarCotizacion/${numCot}/${nameInsurer}`;
    let res;
    axios.get(URL, {
      responseType: 'blob',
      headers: {
        Accept: 'application/pdf',
        Authorization: idToken,
      },
    })
    .then((response) => {
      const content = response.headers['content-type'];
      download(response.data, `${numCot}.pdf`, content);
      res = content;
    });
    return res
  } catch (err) {
    throw onError(`cotizacion no encontrada ${err}`);
  }
};

export const statusPdf = async (
  numCot: any,
  nameInsurer: any,
  onError: (text: string) => void
): Promise<any> => {
  try {
    const users = await new HttpRequest().Get<any>(
      `https://api.cotizadorvirtual.co/dev/generarCotizacion/${numCot}/${nameInsurer}`
    );

    return users.data;
  } catch (err) {
    throw onError('No se encontro la cotizacion');
  }
};