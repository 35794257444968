import { HttpRequest } from './HttpRequest';
import { baseUrl, userControl, quotationUrl } from 'utils/ApiRoutes';

/**
 * List all users
 */
export const listAllUsers = async (
  onError: (text: string) => void
): Promise<IListAllUsersResponse> => {
  try {
    const users = await new HttpRequest().Get<IListAllUsersResponse>(
      `${baseUrl}${userControl.cognitoUsers}`
    );

    return users.data;
  } catch (err) {
    throw onError('Ha ocurrido un error al listar los usuarios.');
  }
};

/**
 * List all quotations
 */
export const listAllCotizaciones = async (
  onError: (text: string) => void
): Promise<IUserCotizaciones[]> => {
  try {
    const users = await new HttpRequest().Get<IUserCotizaciones[]>(
      `${baseUrl}${userControl.cotizacionesUsers}`
    );

    return users.data;
  } catch (err) {
    throw onError('Ha ocurrido un error al listar las cotizaciones.');
  }
};

/**
 * List Ranking
 */
export const listRanking = async (
  onError: (text: string) => void
): Promise<IRanking[]> => {
  try {
    const users = await new HttpRequest().Get<IRanking[]>(
      `${baseUrl}/ranking`
    );

    return users.data.rankig;
  } catch (err) {
    throw onError('Ha ocurrido un error al listar el ranking.');
  }
};

/**
 * List all excels
 */
 export const listAllExcel = async (
  onError: (text: string) => void
): Promise<any> => {
  try {
    const excels = await new HttpRequest().Get<any>(
      `${baseUrl}/listaArchivos`
    );

    return excels.data.listFiles;
  } catch (err) {
    throw onError('Ha ocurrido un error al listar los Excel.');
  }
};

/**
 * download excel
 */
 export const downloadExcel = async (
   Key: any ,
  onError: (text: string) => void
): Promise<any> => {
  try {
    const excels = await new HttpRequest().Get<any>(
      `${baseUrl}/linkDescarga/${Key}`
    );

    return excels.data;
  } catch (err) {
    throw onError('Ha ocurrido un error al listar los Excel.');
  }
};

/**
 * Status User  
 */
export const statusUser = async (
  onError: (text: string) => void
): Promise<any> => {
  try {
    const email = localStorage.getItem('id_email')
    const users = await new HttpRequest().Get<any>(
      `https://api.cotizadorvirtual.co/dev/obtenerUsuario/${email}`
    );

    return users.data;
  } catch (err) {
    window.location.reload();
    throw onError('Su usuario a sido eliminado.');
  }
};



/**
 * Get the resume of quotation
 */
export const getQuotationResume = async (
  typeQuoteWithId: string
): Promise<IQuotationResumeService> => {
  try {
    const resume = await new HttpRequest().Get<IQuotationResumeService>(
      `${baseUrl}${quotationUrl.quoteResume}${typeQuoteWithId}`
    );
    return resume.data;
  } catch (err) {
    throw new Error(err);
  }
};
