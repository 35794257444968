import * as React from 'react';
import {
  Button,
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
} from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { downloadPdf, statusPdf } from 'services/AdminActUser';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import MuiAlert from '@material-ui/lab/Alert';
import HealthImage from '../../../assets/Branding/undraw_export_files_re_99ar.png';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: 1000,
      minHeight: 400,
      display: 'flex',
      marginTop: 80,
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 220,
    },
    button: {
      margin: theme.spacing(1),
    },
    details: {
      display: 'flex',
      flexDirection: 'column',
    },
    content: {
      flex: '1 0 auto',
    },
    cover: {
      width: 400,
    },
    progress: {
      margin: theme.spacing(1),
    },
    container: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      height: '100%',
    },
  })
);

const DownloadPdf = () => {
  const classes = useStyles();

  const [numCot, setNumCot] = React.useState<any>('');
  const [homeV, setHomeV] = React.useState('');
  const [openSnack, setOpenSnack] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [loading, setLoading] = React.useState<boolean>(false);

  const handleChangeNumCot = (event: any) => {
    setNumCot(event.target.value);
  };

  const handleChanges = (event: any) => {
    setHomeV(event.target.value);
  };

  const download = async () => {
    setLoading(true);
    statusPdf(numCot, homeV, onError).then((response) => {
      if (response === undefined) {
        setOpenSnack(true);
        setErrorMessage('Cotizacion No Encontrada');
      } else {
        downloadPdf(numCot, homeV, onError);
        setLoading(false);
      }
    });
  };

  const onError = (error: string) => {
    setOpenSnack(true);
    setErrorMessage(error);
    setLoading(false);
  };

  const onCloseSnackBar = () => {
    setOpenSnack(false);
  };

  return (
    <React.Fragment>
      <Grid container spacing={3}>
        <Grid item xs={2} />
        <Grid item xs={8}>
          <Card className={classes.root}>
            <div className={classes.details}>
              <CardContent>
                <Typography gutterBottom variant="h4" component="h2">
                  Descarga PDF
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  Permite descargar PDF según las necesidades de cada aseguradora.
                </Typography>
              </CardContent>
              <CardActions>
                <FormControl className={classes.formControl}>
                  <InputLabel id="demo-simple-select-disabled-label">Aseguradora</InputLabel>
                  <Select
                    labelId="demo-simple-select-disabled-label"
                    id="demo-simple-select-disabled"
                    value={homeV}
                    onChange={handleChanges}>
                    <MenuItem value="equidad">Equidad</MenuItem>
                    <MenuItem value="hdi">Hdi</MenuItem>
                    <MenuItem value="axa">Axa</MenuItem>
                    <MenuItem value="previsora">Previsora</MenuItem>
                    <MenuItem value="solidaria">Solidaria</MenuItem>
                  </Select>
                </FormControl>
              </CardActions>
              <CardActions>
                <TextField
                  id="outlined-basic"
                  label="Número  de Cotización"
                  variant="outlined"
                  size="small"
                  style={{ margin: '5px', width: '' }}
                  value={numCot}
                  onChange={handleChangeNumCot}
                />
              </CardActions>
              <CardActions>
                {numCot === '' ? (
                  <Button
                    disabled={true}
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    onClick={() => download()}>
                    DESCARGAR ARCHIVO
                  </Button>
                ) : (
                  <div className={classes.container}>
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.button}
                      onClick={() => download()}>
                      DESCARGAR ARCHIVO
                    </Button>
                    {loading && <CircularProgress className={classes.progress} size={30} />}
                  </div>
                )}
              </CardActions>
            </div>
            <CardMedia className={classes.cover} image={HealthImage} title="download" />
          </Card>
        </Grid>
      </Grid>
      <Grid item xs={12} />
      <Grid item xs={12} />
      <Grid item xs={12} />
      <Grid item xs={12} />
      <Snackbar open={openSnack} autoHideDuration={6000} onClose={onCloseSnackBar}>
        <MuiAlert elevation={6} variant="filled" onClose={onCloseSnackBar} severity="error">
          {errorMessage}
        </MuiAlert>
      </Snackbar>
    </React.Fragment>
  );
};

export default DownloadPdf;
