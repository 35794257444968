import React, { FC } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { useStyles } from './Styles';

const Layout: FC = ({ children }) => {
  const classes = useStyles();

  return (
    <Grid className={classes.root}> 
      <CssBaseline />
      <Paper className={classes.formContainer}>
        {children}
      </Paper>
    </Grid>
  );
};

export default Layout;
