import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  title: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 700,
  },
  rowTitle: {
    fontWeight: 'bold',
  },
}));
