import axios from 'axios';
import { Auth } from 'aws-amplify';

export const listAllCotizaciones = async (
  email: any,
  start: any,
  end: any,
  cond: any,
  onError: (text: string) => void
): Promise<any> => {
  try {
    const endCorr = end + 86340000
    if (start === end) {
      const URL = 'https://api.cotizadorvirtual.co/dev/filtrar';
      const currentSession = await Auth.currentSession();
      const token = currentSession.getIdToken().getJwtToken();
      const body = {
        userName: email,
        star_date: start,
        end_date: endCorr,
        getAllIfCondition: cond
      }
      const headers = {
        headers: {
          Authorization: token,
        },
      };
      const response = await axios.post(URL, body, headers);
      return response;
    }else {
      const URL = 'https://api.cotizadorvirtual.co/dev/filtrar';
      const currentSession = await Auth.currentSession();
      const token = currentSession.getIdToken().getJwtToken();
      const body = {
        userName: email,
        star_date: start,
        end_date: end,
        getAllIfCondition: cond
      }
      const headers = {
        headers: {
          Authorization: token,
        },
      };
      const response = await axios.post(URL, body, headers);
      return response;
    }
    
  } catch (e) {
    throw onError('Ha ocurrido un error al listar los usuarios.');
  }
};
