import * as React from 'react';
import { Home as HomeIcon, Description, PeopleAlt, CloudUpload, FindInPage, PictureAsPdf } from '@material-ui/icons';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import Home from 'pages/Home/Home';
import SummaryOpportunities from 'pages/SummaryOpportunities/SummaryOpportunities';
import { IStructureNavigation } from 'types/Navigation/NavigationStructure';
import CarsQuotation from 'pages/CarsQuotation/CarsQuotation';
import TruckQuotation from "../pages/TruckQuotation/TruckQuotation";
import HomeQuotation from 'pages/HomeQuotation/HomeQuotation';
import MotoQuotation from 'pages/MotoQuotation/MotoQuotation';
import QuotationResume from 'pages/QuotationResume/QuotationResume';
import QuotationResumeHome from 'pages/QuotationResumeHome/QuotationResumeHome';
import QuotationResumeMoto from 'pages/QuotationResumeMoto/QuotationResumeMoto';
import QuotationResumeTruck from "../pages/QuotationResumeTruck/QuotationResumeTruck";
import UserControl from 'pages/Admin/UserControl/UserControl';
import FilterCot from 'pages/Admin/FilterCot/FilterCot';
import Dashboard from 'pages/Admin/Dashboard/Dashboard';
import DownloadPdf from 'pages/Admin/DownloadPdf/DownloadPdf';
import DownloadExcel from 'pages/Admin/DownloadExcel/DownloadExcel';
import UploadFasecolda from 'pages/Admin/UploadFasecolda/UploadFasecolda';
import TrendingUpOutlinedIcon from '@material-ui/icons/TrendingUpOutlined';

export const naturalStructureNavigation: IStructureNavigation = {
  slider: [
    {
      id: 0,
      label: 'Inicio',
      link: '/app/inicio',
      icon: <HomeIcon />,
    },
    {
      id: 1,
      label: 'Resumen Oportunidades',
      link: '/app/resumen-oportunidades',
      icon: <Description />,
    },
    {
      id: 6,
      label: 'DownloadPdf',
      link: '/app/pdflist',
      icon: <PictureAsPdf />,
    }
  ],
  route: [
    {
      path: '/app/inicio',
      component: Home,
    },
    {
      path: '/app/resumen-oportunidades',
      component: SummaryOpportunities,
    },
    {
      path: '/app/cotizador/autos',
      component: CarsQuotation,
    },
    {
      path: '/app/cotizador/hogar-vida',
      component: HomeQuotation,
    },
    {
      path: '/app/cotizador/motos',
      component: MotoQuotation,
    },
    {
      path: '/app/cotizador/pesados',
      component: TruckQuotation,
    },
    {
      path: '/app/cotizador/autos/resumen/:id',
      component: QuotationResume,
    },
    {
      path: '/app/cotizador/hogar/resumen/:id',
      component: QuotationResumeHome,
    },
    {
      path: '/app/pdflist',
      component: DownloadPdf,
    },
    {
      path: '/app/cotizador/motos/resumen/:id',
      component: QuotationResumeMoto,
    },
    {
      path: '/app/cotizador/pesados/resumen/:id',
      component: QuotationResumeTruck,
    }
  ],
};

export const adminStructureNavigation: IStructureNavigation = {
  slider: [
    {
      id: 0,
      label: 'Inicio',
      link: '/app/inicio',
      icon: <HomeIcon />,
    },
    {
      id: 1,
      label: 'Resumen Oportunidades',
      link: '/app/resumen-oportunidades',
      icon: <Description />,
    },
    {
      id: 2,
      label: 'Control de usuarios',
      link: '/app/control-usuarios',
      icon: <PeopleAlt />,
    },
    {
      id: 4,
      label: 'Cotizaciones',
      link: '/app/filter',
      icon: <FindInPage />,
    },
    {
      id: 7,
      label: 'DownloadExcel',
      link: '/app/excellist',
      icon: <CloudDownloadIcon />,
    },
    {
      id: 3,
      label: 'Actualizar Fasecolda',
      link: '/app/actualizar-fasecolda',
      icon: <CloudUpload />,
    },
    {
      id: 6,
      label: 'DownloadPdf',
      link: '/app/pdflist',
      icon: <PictureAsPdf />,
    },
    {
      id: 5,
      label: 'Dashboard',
      link: '/app/dashboard',
      icon: <TrendingUpOutlinedIcon />,
    },
  ],
  route: [
    {
      path: '/app/control-usuarios',
      component: UserControl,
    },
    {
      path: '/app/filter',
      component: FilterCot,
    },
    {
      path: '/app/dashboard',
      component: Dashboard,
    },
    {
      path: '/app/pdflist',
      component: DownloadPdf,
    },
    {
      path: '/app/excellist',
      component: DownloadExcel,
    },
    {
      path: '/app/inicio',
      component: Home,
    },
    {
      path: '/app/resumen-oportunidades',
      component: SummaryOpportunities,
    },
    {
      path: '/app/cotizador/autos',
      component: CarsQuotation,
    },
    {
      path: '/app/cotizador/pesados',
      component: TruckQuotation,
    },
    {
      path: '/app/cotizador/hogar-vida',
      component: HomeQuotation,
    },
    {
      path: '/app/cotizador/motos',
      component: MotoQuotation,
    },
    {
      path: '/app/cotizador/autos/resumen/:id',
      component: QuotationResume,
    },
    {
      path: '/app/cotizador/hogar/resumen/:id',
      component: QuotationResumeHome,
    },
    {
      path: '/app/cotizador/motos/resumen/:id',
      component: QuotationResumeMoto,
    },
    {
      path: '/app/cotizador/pesados/resumen/:id',
      component: QuotationResumeTruck,
    },
    {
      path: '/app/actualizar-fasecolda',
      component: UploadFasecolda,
    },
  ],
};
