/* tslint:disable: jsx-no-lambda */
import * as React from 'react';
import {
  Grid,
  FormControl,
  Typography,
  Select,
  MenuItem,
} from '@material-ui/core';
import { Controller } from 'react-hook-form';
import AsyncSelect from 'react-select/async';

import InputField from 'components/Global/InputLabel/InputLabel';
import { useStyles } from './styles';
import { BootstrapInput } from 'components/Global/PickerSelect/PickerSelect';
import { DATE_INVALID, REQUIRED_FIELD } from 'constants/notifyConstants';
import NumberFormat, { NumberFormatValues } from 'react-number-format';
import { CALENDAR_DATE_VALIDATION } from 'constants/index';
import { INumberFormatMoneyValuePros } from 'types/CarsQuotation/IVehicleInfo';

const NumberFormatMoneyValue = (props: INumberFormatMoneyValuePros) => {
  const onChangeValue = (e: NumberFormatValues) => {
    return props.onChange(e.floatValue);
  };

  return (
    <NumberFormat
      customInput={InputField}
      required={props.required ? props.required : false}
      thousandSeparator={true}
      prefix={'$'}
      placeholder="$0"
      onValueChange={onChangeValue}
      value={props.value}
      label={props.labelContent}
      id={props.idComponent}
      disabled={props.disabled}
    />
  );
};

const UserDataVida: React.FC<any> = ({
  control,
  occupationItems,
  errors,
  loadCitiesOptions,
  handleInputCitiesChange,
}) => {
  const classes = useStyles();

  return (
    <>
      <Grid item xs={12} sm={3}>
        <Typography className={classes.label}>Fecha de Nacimiento</Typography>
        <Controller
          name="fBorn"
          control={control}
          render={({ value, onChange }) => (
            <NumberFormat
              customInput={InputField}
              value={value}
              onValueChange={(e: NumberFormatValues) => onChange(e.formattedValue)}
              format="##/##/####"
              placeholder="DD/MM/YYYY"
              mask={['D', 'D', 'M', 'M', 'Y', 'Y', 'Y', 'Y']}
            />
          )}
          rules={{
            required: REQUIRED_FIELD,
            pattern: {
              value: CALENDAR_DATE_VALIDATION,
              message: DATE_INVALID,
            },
          }}
        />
        {errors.fBorn && errors.fBorn.type && (
          <Typography variant="caption" display="block" gutterBottom style={{ color: 'red' }}>
            {errors.fBorn.message}
          </Typography>
        )}
      </Grid>
      <Grid item xs={12} sm={3}>
        <FormControl className={classes.margin}>
          <Typography className={classes.label}>Ocupación</Typography>
          <Controller
            as={
              <Select
                labelId="demo-customized-select-label"
                id="demo-customized-select"
                input={<BootstrapInput />}>
                {occupationItems.map((menuItem: any, index: any) => (
                  <MenuItem key={index} value={menuItem.value}>
                    {menuItem.label}
                  </MenuItem>
                ))}
              </Select>
            }
            control={control}
            name="occupation"
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={3}>
        <FormControl className={classes.margin}>
          <Typography className={classes.label}>Ciudad</Typography>
          <Controller
            as={
              <AsyncSelect
                cacheOptions
                loadOptions={loadCitiesOptions}
                defaultOptions
                onInputChange={handleInputCitiesChange}
                placeholder="Seleccionar..."
              />
            }
            control={control}
            name="cityLive"
            rules={{
              required: REQUIRED_FIELD,
            }}
          />
          {errors.cityCirculation && errors.cityCirculation.type === 'required' && (
            <Typography variant="caption" display="block" gutterBottom style={{ color: 'red' }}>
              {errors.cityCirculation.message}
            </Typography>
          )}
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={3}>
        <Controller
          render={(props) => (
            <NumberFormatMoneyValue
              labelContent="Saldo de Capital Credito"
              idComponent="securedValue"
              {...props}
              disabled={false}
            />
          )}
          control={control}
          name="valueDebtLive"
          rules={{
            required: REQUIRED_FIELD,
          }}
        />
        {errors.valueInsured && errors.valueInsured.type === 'required' && (
          <Typography variant="caption" display="block" gutterBottom style={{ color: 'red' }}>
            {errors.valueInsured.message}
          </Typography>
        )}
      </Grid>
    </>
  );
};

export default UserDataVida;
