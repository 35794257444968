/* tslint:disable: jsx-no-lambda */
import * as React from 'react';
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  Grid,
  FormControl,
  FormLabel,
  Typography,
  Select,
  MenuItem,
} from '@material-ui/core';
import { Controller } from 'react-hook-form';

import InputField from 'components/Global/InputLabel/InputLabel';
import { useStyles } from './styles';
import { BootstrapInput } from 'components/Global/PickerSelect/PickerSelect';
import { REQUIRED_FIELD } from 'constants/notifyConstants';

const UserDataUser: React.FC<any> = ({
  control,
  documentTypeItems,
  errors,
}) => {
  const classes = useStyles();
  return (
    <>
      <Grid item xs={12} sm={6}>
        <FormControl component="fieldset">
          <FormLabel component="legend" className={classes.label}>
            Género:
          </FormLabel>
          <Controller
            as={
              <RadioGroup aria-label="gender" row>
                <FormControlLabel value="f" control={<Radio color="primary" />} label="Femenino" />
                <FormControlLabel value="m" control={<Radio color="primary" />} label="Masculino" />
              </RadioGroup>
            }
            defaultValue={false}
            name="gender"
            control={control}
            rules={{
              required: REQUIRED_FIELD,
            }}
          />
          {errors.gender && errors.gender.type === 'required' && (
            <Typography variant="caption" display="block" gutterBottom style={{ color: 'red' }}>
              {errors.gender.message}
            </Typography>
          )}
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={3}>
        <Controller
          as={InputField}
          name="firstName"
          label="Nombres"
          autoComplete="given-name"
          id="firstName"
          required
          control={control}
          rules={{
            required: REQUIRED_FIELD,
          }}
        />
        {errors.firstName && errors.firstName.type === 'required' && (
          <Typography variant="caption" display="block" gutterBottom style={{ color: 'red' }}>
            {errors.firstName.message}
          </Typography>
        )}
      </Grid>
      <Grid item xs={12} sm={3}>
        <Controller
          as={InputField}
          name="lastName"
          label="Apellidos"
          autoComplete="family-name"
          id="lastName"
          required
          control={control}
          rules={{
            required: REQUIRED_FIELD,
          }}
        />
        {errors.lastName && errors.lastName.type === 'required' && (
          <Typography variant="caption" display="block" gutterBottom style={{ color: 'red' }}>
            {errors.lastName.message}
          </Typography>
        )}
      </Grid>
      <Grid item xs={12} sm={3}>
      </Grid>
      <Grid item xs={12} sm={3}>
      </Grid>
      <Grid item xs={12} sm={3}>
        <FormControl className={classes.margin}>
          <Typography className={classes.label}>Tipo Documento</Typography>
          <Controller
            as={
              <Select
                labelId="demo-customized-select-label"
                id="demo-customized-select"
                input={<BootstrapInput />}>
                {documentTypeItems.map((menuItem: any, index: any) => (
                  <MenuItem key={index} value={menuItem.value}>
                    {menuItem.label}
                  </MenuItem>
                ))}
              </Select>
            }
            control={control}
            name="documentType"
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={3}>
        <Controller
          as={InputField}
          control={control}
          name="nDocument"
          label="No. Documento"
          id="document"
          required
          type="number"
          defaultValue={false}
          rules={{
            required: REQUIRED_FIELD,
          }}
        />
        {errors.nDocument && errors.nDocument.type === 'required' && (
          <Typography variant="caption" display="block" gutterBottom style={{ color: 'red' }}>
            {errors.nDocument.message}
          </Typography>
        )}
      </Grid>
    </>
  );
};

export default UserDataUser;
