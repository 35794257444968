import * as React from 'react';
import DataTable from 'react-data-table-component';
import matchSorter from 'match-sorter';
import { TEventTarget } from 'types';
import { Button, TextField,  Snackbar, Checkbox, Typography } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import { useStyles } from './styles';
import { useHistory } from 'react-router-dom';
import { formatDate} from 'utils/utils';
import { listAllCotizaciones } from 'services/AdminFilterService';
import moment from 'moment';

const FilterCot = () => {
  const classes = useStyles();
  const history = useHistory();

  const [filteredOpportunities, setFilteredOpportunities] = React.useState<string>('');
  const [userCollection, setUserCollection] = React.useState<ICoFilter[] | null>(null);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [openSnack, setOpenSnack] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [filterEmail, setFilterEmail] = React.useState<any>('');
  const [filterStart, setFilterStart] = React.useState<any>('');
  const [filterEnd, setFilterEnd] = React.useState<any>('');
  const [checked, setChecked] = React.useState(false);

  // filter items according the data
  const filteredItems =
    userCollection !== null
      ? matchSorter(userCollection, filteredOpportunities, {
          keys: ['consecutiveTotalNumber', 'licenseVehicle'],
        })
      : [];

  // on Error
  const onError = (error: string) => {
    setOpenSnack(true);
    setErrorMessage(error);
  };

  // launch snackBar
  const onCloseSnackBar = () => {
    setOpenSnack(false);
  };

  // handler filter data table
  const onFilterSearch = (e: TEventTarget) => setFilteredOpportunities(e.target.value);
  
  const handleChangeEmail = (event: any) => {
    setFilterEmail(event.target.value);
  };
  
  const handleChangeStart = (event: any) => {
    setFilterStart(event.target.value);
  };

  const handleChangeEnd = (event: any) => {
    setFilterEnd(event.target.value);
  };

  const handleChange = (event: any) => {
    setChecked(event.target.checked);
  };

  const resumeCot = (uuid: any, type: any) => {
    switch (type) {
      case 'HogarVidaDeudores':
        history.push({
          pathname: `/app/cotizador/hogar/resumen/${uuid}`,
        });
        break; 
      case 'Auto':
        history.push({
          pathname: `/app/cotizador/autos/resumen/${uuid}`,
        });
        break; 
      case 'Motos':
        history.push({
          pathname: `/app/cotizador/motos/resumen/${uuid}`,
        });
        break;
      case 'Auto_P':
        history.push({
          pathname: `/app/cotizador/pesados/resumen/${uuid}`,
        });
        break;
    }
  };

  const onFilter = () => {
    setLoading(true);
    if(filterEmail) {
      const millisecondsStart = moment(filterStart, "YYYY-MM-DD").format('x');
      const start = parseInt(millisecondsStart);
      const millisecondsEnd = moment(filterEnd, "YYYY-MM-DD").format('x');
      const end = parseInt(millisecondsEnd);
      const email = filterEmail.toString();
      const cond = checked
      listAllCotizaciones(email, start, end, cond, onError).then((response) => {
        if(response.data.message === 'No hay cotizaciones disponibles para las fechas ingresadas.'){
          setUserCollection(null);
          setLoading(false);
        }else {
          if(start > end){
            setUserCollection(null);
            setLoading(false);
          }else {
            setUserCollection(response.data.cotizaciones);
            setLoading(false);
          }
        }
      });
    }else {
      setUserCollection(null);
      setLoading(false);
    }
  };

  const columns = [
    {
      name: 'Num',
      selector: (row: ICoFilter) => row.consecutiveTotalNumber,
      sortable: true,
    },
    {
      name: 'Documento',
      selector: (row: ICoFilter) => row.nDocument,
      sortable: true,
    },
    {
      name: 'Nombre',
      selector: (row: ICoFilter) => row.firstName + ' ' + row.lastName ,
      sortable: true,
    },
    {
      name: 'Fecha',
      selector: (row: ICoFilter) => formatDate(row.createdAt),
      sortable: true, 
    },
    {
      name: 'placa',
      selector: (row: ICoFilter) => row.licenseVehicle,
      sortable: true,
    },
    {
      name: 'email',
      selector: (row: ICoFilter) => row.email,
      sortable: true,
    },
    {
      name: 'Detalles Cotizacion',
      selector: (row: IUserCotizaciones) => (
        <Button
          variant="contained"
          size="small"
          color="primary"
          onClick={() => resumeCot(row.uuid, row.quoteType)}>
          Detalle
        </Button>
      ), 
    },
  ];

  React.useEffect(() => {
    document.title = 'Filtro Cotizaciones';
  }, []);

  return (
    <React.Fragment>
      <DataTable
        title="Filtro"
        columns={columns}
        progressPending={loading}
        paginationComponentOptions={{
          rowsPerPageText: 'Filas por página:',
          rangeSeparatorText: 'de',
        }}
        noDataComponent="No se han encontrado registros"
        data={filteredItems}
        defaultSortField="director"
        pagination={true}
        highlightOnHover
        subHeader={true}
        subHeaderComponent={
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {/* <FormControl className={classes.formControl}>
              <InputLabel id="demo-simple-select-disabled-label">Tipo Cotizacion</InputLabel>
              <Select
                labelId="demo-simple-select-disabled-label"
                id="demo-simple-select-disabled"
                value={homeV}
                onChange={handleChanges}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value={10}>Hogar</MenuItem>
                <MenuItem value={20}>Vida</MenuItem>
              </Select>
            </FormControl> */}
            <TextField
            required
              id="outlined-basic"
              label="Email"
              variant="outlined"
              size="small"
              style={{ margin: '5px', width: '' }}
              value={filterEmail}
              onChange={handleChangeEmail}
            />
            <TextField
              id="outlined-basic"
              type="date"
              variant="outlined"
              size="small"
              style={{ margin: '5px', width: '' }}
              value={filterStart}
              onChange={handleChangeStart}
            />
            <TextField
              id="outlined-basic"
              type="date"
              variant="outlined"
              size="small"
              style={{ margin: '5px', width: '' }}
              value={filterEnd}
              onChange={handleChangeEnd}
            />
            <Checkbox
              checked={checked}
              onChange={handleChange}
              inputProps={{ 'aria-label': 'secondary checkbox' }}
            />
            <Typography >
              Mostrar Histórico
            </Typography>
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={onFilter}>
              Buscar
            </Button>
            <TextField
              id="outlined-basic"
              label="Buscar"
              variant="outlined"
              size="small"
              style={{ margin: '5px' }}
              onChange={onFilterSearch}
            />
          </div>
        }
        fixedHeaderScrollHeight="500px"
      />
      <Snackbar open={openSnack} autoHideDuration={6000} onClose={onCloseSnackBar}>
        <MuiAlert elevation={6} variant="filled" onClose={onCloseSnackBar} severity="error">
          {errorMessage}
        </MuiAlert>
      </Snackbar>
    </React.Fragment>
  );
};

export default FilterCot;
