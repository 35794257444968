import * as React from 'react';

import { Paper, Button, Typography, Grid, Snackbar, CircularProgress, FormControl, MenuItem, Select } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import { useHistory, useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import { useStyles } from './styles';
import UserData from 'components/CarsQuotation/UserData/UserData';
import SubTitle from 'components/CarsQuotation/SubTitle/SubTitle';
import QuoteType from 'components/CarsQuotation/QuoteType/QuoteType';
import VehicleInfo from 'components/CarsQuotation/VehicleInfo/VehicleInfo';
import {
  insures,
  civilStatus,
  quoteTypeData,
  documentType,
  stratum,
  educationLevel as educationLevelItems,
  occupation,
  profession,
  services,
  useForVehicleParticular,
  useForVehiclePublic,
  defaultValues,
} from './resources';
import { TEventTarget } from 'types';
import { formatDate } from 'utils/utils';
import {
  createQuotation,
  getFasecoldaCode,
  getValidateQuotation,
  getRefFasecolda,
} from 'services/QuotationService';
import { cities } from './cities';
import matchSorter from 'match-sorter';
import { ISelect } from 'types/CarsQuotation/IDataInsure';
import { LICENSE_PLATE_VALIDATION } from 'constants/index';
import swal from 'sweetalert';
import { BootstrapInput } from 'components/Global/PickerSelect/PickerSelect';

const CarsQuotation: React.FC = () => {
  // styles
  const classes = useStyles();

  const location = useLocation() as ILocationCarsQuotation;

  // useForm
  const { handleSubmit, control, errors, watch, setValue } = useForm<ICarsQuotation>({
    defaultValues,
  });
  const watchAllFields = watch(['tVehicle', 'service', 'checkAccesories']);

  // states
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [openSnack, setOpenSnack] = React.useState<boolean>(false);

  const [refsFasecolda, setRefsFasecolda] = React.useState<any>({});
  const [disabledFasecoldaField, setDisabledFasecoldaField] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [typeQuote, setTypeQuote] = React.useState('Auto');

  const handleChangeTypeQuote = (event: any) => {
    setTypeQuote(event.target.value);
  };

  // on Error
  const onError = (error: string) => {
    setOpenSnack(true);
    setErrorMessage(error);
  };

  // navigation hook
  const history = useHistory();

  // cities search
  const filterCities = (inputValue: string) => {
    const filterCitiesBySearch =
      cities !== null
        ? matchSorter(cities, inputValue, {
            keys: ['value', 'label'],
          })
        : [];
    return filterCitiesBySearch;
  };

  const loadCitiesOptions = (inputValue: string, callback: any) => {
    setTimeout(() => {
      callback(filterCities(inputValue));
    }, 500);
  };

  const handleInputCitiesChange = (newValue: string) => {
    const inputValue = newValue;
    return inputValue;
  };

  // launch snackBar
  const onCloseSnackBar = () => {
    setOpenSnack(false);
  };

  // license plate validation
  const onValidateLicensePlate = (event: TEventTarget, props: any) => {
    const validationRegex = LICENSE_PLATE_VALIDATION;
    const plate = event.target.value;

    if (validationRegex.test(plate)) {
      setLoading(true);
      getFasecoldaCode(plate, onError)
        .then(({ fasecolda, modelo }) => {
          if (watchAllFields.tVehicle === 'usado' && fasecolda && modelo) {
            const fModelo = `f${modelo}`;

            setValue('codeFasecolda', fasecolda);
            setValue('modelo', modelo);

            setFasecoldaRef(fasecolda).then((response) =>
              setValue('valueInsured', response[fModelo])
            );
            setDisabledFasecoldaField(true);
            setLoading(false);
          } else {
            swal(
              '¡Alto!',
              'Esta placa está matricula en el runt o esta siendo usada en otro vehiculo.',
              'warning'
            );
            setLoading(false);
          }
        })
        .catch(() => setLoading(false));
      return props.onBlur(plate);
    } else {
      onError('Ups, La placa ingresada no es valida.');
      setDisabledFasecoldaField(false);
      return props.onBlur(plate);
    }
  };

  const onCreateQuotation = (bodyObject: any) => {
    createQuotation(bodyObject, onError).then(({ uuid }) => {
      history.push({
        pathname: `/app/cotizador/autos/resumen/${uuid}`,
      });
      return uuid;
    });
  };

  const setFasecoldaRef = async (fasecoldaCode: string | undefined) => {
    if (fasecoldaCode !== undefined) {
      setLoading(true);
      try {
        const refs = await getRefFasecolda(fasecoldaCode, onError);
        setRefsFasecolda(refs.guiaFasecolda.data);
        setValue('brand', refs.guiaFasecolda.data.fMarca);
        setValue('ref1', refs.guiaFasecolda.data.fReferencia1);
        setValue('ref2', refs.guiaFasecolda.data.fReferencia2);
        setValue('ref3', refs.guiaFasecolda.data.fReferencia3);

        setLoading(false);
        return refs.guiaFasecolda.data;
      } catch (err) {
        setLoading(false);
      }
    }
  };

  const onChangeFasecolda = (event: TEventTarget, props: any) => {
    if (event.target.value !== '') {
      setDisabledFasecoldaField(false);
      setFasecoldaRef(event.target.value);
      return props.onBlur(event.target.value);
    }
    return props.onBlur(event.target.value);
  };

  const onModeloChange = (event: TEventTarget, props: any) => {
    if (event.target.value !== '') {
      setLoading(true);
      const fModelo = `f${event.target.value}`;
      const valueInsure = refsFasecolda[fModelo];
      setValue('valueInsured', valueInsure);
      setLoading(false);
      return props.onBlur(event.target.value);
    } else {
      setLoading(false);
      return props.onBlur(event.target.value);
    }
  };

  /**
   * on submit data
   * @param formDataState use form hook object
   */
  const onSubmitQuotationForm = (formDataState: ICarsQuotation) => {
    const carModelo = formDataState.modelo;
    const valueInsuredAsNumber = parseInt(formDataState.valueInsured, 10);

    if (valueInsuredAsNumber !== 0) {
      const newSelectedInsurers: string[] = [];

      formDataState.selectedInsurers.map((insure: ISelect) =>
        newSelectedInsurers.push(insure.label)
      );

      const bodyObject = {
        ...formDataState,
        quoteType: typeQuote,
        selectedInsurers: newSelectedInsurers,
        cityCirculation:
          formDataState.optionalCity.value === undefined
            ? [formDataState.cityCirculation.value]
            : [formDataState.cityCirculation.value, formDataState.optionalCity.value],
        nDocument: parseInt(formDataState.nDocument, 10),
        stratum: parseInt(formDataState.stratum, 10),
        modelo: carModelo !== undefined && parseInt(carModelo, 10),
        valueInsured: valueInsuredAsNumber,
        valueAccesories: formDataState.valueAccesories
          ? parseInt(formDataState.valueAccesories, 10)
          : 0,
        fBorn: formDataState.fBorn,
      };

      getValidateQuotation(formDataState.licenseVehicle, onError)
      .then((response) => {
        setLoading(true);
        if (response.message) {
          onCreateQuotation(bodyObject);
          setLoading(false);
        } else {
          swal({
            title: 'Ya existe una oportunidad vigente en Salesforce asociada a esta cotización.',
            content: {
              element: "span", 
              attributes: {
                innerHTML: `
                  <div style="text-align: left;">
                    <b>Fecha de cotización:</b> ${formatDate(response.createdAt)}<br>
                    <b>Placa:</b> ${response.licenseVehicle}<br>
                    <b>Modelo:</b> ${response.modelo}<br>
                    <b>Asesor:</b> ${response.createdBy.name}<br>
                    <a href="https://dev.cotizadorvirtual.co/app/cotizador/autos/resumen/${response.uuid}" target="_blank">
                      Ver Cotización
                    </a>
                  </div>
                  <style>
                  .swal-button-container {
                      display: flex;
                      justify-content: center;
                      gap: 10px;
                      width: 100%;
                      margin-top: 20px;
                    }
                    .swal-button {
                      flex: 1;
                    }
                  </style>`
            },
            },
            icon: 'error',
            buttons: {
              cancel: 'Cancelar',
              confirm: {
                text: 'Continuar',
                value: 'continue',
              },
            },
          } as any).then((value) => {
            if (value === 'continue') {
              swal({
                title: '¿Está seguro de querer continuar?',
                text: 'Al recotizar, se actualizarán los datos existentes en la oportunidad. ¿Está seguro de continuar?',
                icon: 'warning',
                buttons: ['Cancelar', 'Sí, continuar'],
                dangerMode: true,
                content: {
                  element: "span", 
                  attributes: {
                    innerHTML:`
                    <style>
                    .swal-button-container {
                        display: flex;
                        justify-content: center;
                        gap: 10px;
                        width: 100%;
                        margin-top: 20px;
                      }
                      .swal-button {
                        flex: 1;
                      }
                    </style>
                    `
                  }
                }
              }).then((confirmValue) => {
                if (confirmValue) {
                  onCreateQuotation(bodyObject);
                } else {
                  swal('Proceso cancelado', 'No se realizó la cotización.', 'info');
                }
              });
            }
          });
        }
        setLoading(false);
      })
      .catch(() => setLoading(false));  
    } else {
      swal(
        `Lo sentimos`,
        `El valor asegurado no puede ser 0, revisa el formulario si los datos ingresados son corrector e intenta de nuevo.`,
        'error'
      );
    }
  };

  // header title
  React.useEffect(() => {
    document.title = 'Cotizador de autos';
    // set hook form value
    if (location.state !== undefined) {
      const quoteByLocation = location.state.quotation;
      const quotationTransform = Object.entries(quoteByLocation);
      quotationTransform.map((quote) => setValue(quote[0], quote[1]));
    }
  }, [location, setValue]);

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit(onSubmitQuotationForm)}>
        <Paper className={classes.paper}>
          <Typography component="h1" variant="h4" align="center" className={classes.title}>
          Cotizador - Seguro Todo Riesgo Autos
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <SubTitle title="Tipo de Cotización" />
            </Grid>
            <QuoteType
              insures={insures}
              control={control}
              quoteType={quoteTypeData}
              disabledInsures={false}
              disabledQuoteType
            />
            <Grid item xs={12} sm={4}>
              <FormControl className={classes.margin} disabled>
                <Typography className={classes.label}>Ramo a Cotizar</Typography>
                <Select
                  labelId="demo-customized-select-label"
                  id="demo-customized-select"
                  value={typeQuote}
                  onChange={handleChangeTypeQuote}
                  input={<BootstrapInput />}>
                  <MenuItem value={'Auto'}>Auto</MenuItem>
                  <MenuItem value={'moto'}>Moto</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <SubTitle title="Datos Personales" />
            </Grid>
            <UserData
              control={control}
              civilStatusItems={civilStatus}
              documentTypeItems={documentType}
              stratumItems={stratum}
              educationLevelItems={educationLevelItems}
              occupationItems={occupation}
              professionItems={profession}
              errors={errors}
            />
            <Grid item xs={12}>
              <SubTitle title="Información del Vehículo" />
            </Grid>
            <VehicleInfo
              control={control}
              serviceItems={services}
              useVehicleItems={
                watchAllFields.service === 'particular'
                  ? useForVehicleParticular
                  : useForVehiclePublic
              }
              loadCitiesOptions={loadCitiesOptions}
              handleInputCitiesChange={handleInputCitiesChange}
              errors={errors}
              onValidateLicensePlate={onValidateLicensePlate}
              checkboxAccesories={watchAllFields.checkAccesories}
              onChangeFasecolda={onChangeFasecolda}
              disabledFasecoldaField={disabledFasecoldaField}
              onModeloChange={onModeloChange}
              disableSecuredValue={watchAllFields.tVehicle === 'nuevo' ? false : true}
            />
            <Grid item xs={12}>
              <div className={classes.buttons}>
                {loading ? (
                  <CircularProgress color="primary" thickness={4} />
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    type="submit"
                    disableElevation>
                    Cotizar
                  </Button>
                )}
              </div>
              {Object.keys(errors).length > 0 && (
                <Typography
                  variant="caption"
                  display="block"
                  gutterBottom
                  align="center"
                  style={{ color: 'red' }}>
                  Hay campos incompletos o con errores, por favor revisarlos.
                </Typography>
              )}
            </Grid>
          </Grid>
        </Paper>
        <Snackbar open={openSnack} autoHideDuration={6000} onClose={onCloseSnackBar}>
          <MuiAlert elevation={6} variant="filled" onClose={onCloseSnackBar} severity="error">
            {errorMessage}
          </MuiAlert>
        </Snackbar>
      </form>
    </React.Fragment>
  );
};

export default CarsQuotation;
