import { Auth } from 'aws-amplify';
import axios, { AxiosRequestConfig, AxiosInstance } from 'axios';
import { createBrowserHistory } from 'history';
import { checkSessionExpiration } from './AuthService';

/**
 * Default header object
 */
const defaultHeader: IHeader = {
  headers: {
    Authorization: null,
    'Content-Type': 'application/json',
  },
};

/**
 * Http request class helper
 */
export class HttpRequest {
  private axiosInstance: AxiosInstance;
  private history = createBrowserHistory();

  constructor() {
    // Crear una instancia de Axios
    this.axiosInstance = axios.create();

    // Agregar un interceptor de respuesta
    this.axiosInstance.interceptors.response.use(
     (response) =>  response ,
      async (error) => {
        if (error.response && error.response.status === 401) {
          // Si el token ha expirado o es inválido, cerramos la sesión
          await this.signOut();
        }
        return Promise.reject(error);
      }
    );
  }

  /**
   * Get request
   * @param {string} request - API URL
   */
  public async Get<T>(request: string): Promise<AxiosRequestConfig> {
    const token = `Bearer ${await this.GetToken()}`;
    const headers = {
      headers: {
        ...defaultHeader.headers,
        Authorization: token,
      },
    };

    const response = await this.axiosInstance.get(request, headers);
    return response;
  }

  /**
   * Post request
   * @param {string} request - API URL
   * @param {object} object - body request
   */
  public async Post<T>(request: string, object: object): Promise<AxiosRequestConfig> {
    const token = `Bearer ${await this.GetToken()}`;
    const headers = {
      headers: {
        ...defaultHeader.headers,
        Authorization: token,
      },
    };

    const response = await this.axiosInstance.post(request, object, headers);
    return response;
  }

  public async Put<T>(request: string, body: any, config: any): Promise<AxiosRequestConfig> {
    const response = await this.axiosInstance.put(request, body, config);
    return response;
  }

  /**
   * Get current token from cognito
   */
  private async GetToken(): Promise<string> {
    try {
      const currentSession = await Auth.currentSession()

      if (await checkSessionExpiration()) {
        await this.signOut()
      }
      return currentSession.getIdToken().getJwtToken();

    } catch (error) {
      await this.signOut();
      throw new Error('No se pudo obtener el token. Se ha cerrado la sesión.');
    }
  }

  /**
   * Cerrar sesión y redirigir al usuario
   */
  private async signOut(): Promise<void> {
    localStorage.removeItem('id_token');
    localStorage.removeItem('id_email');
    localStorage.removeItem('role');

    // Cerrar sesión en Cognito
    await Auth.signOut();

    // Redirigir al usuario a la página de inicio de sesión
    //this.history.push('/app/iniciar-sesion?sessionExpired=true');
    window.location.assign('/app/iniciar-sesion?sessionExpired=true');
  }

  public async GetWithoutToken<T>(request: string): Promise<AxiosRequestConfig> {
    const response = await this.axiosInstance.get(request);
    return response;
  }
}

export default HttpRequest;
