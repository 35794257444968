import CarsImage from 'assets/InsuresCard/autos.svg';
import MotorcycleImage from 'assets/InsuresCard/motos.svg';
import BicycleImage from 'assets/InsuresCard/bicicletas.svg';
import HomeImage from 'assets/InsuresCard/hogar_vida_deudores.svg';
import HealthImage from 'assets/InsuresCard/salud.svg';
import CoOwnershipImage from 'assets/InsuresCard/copropiedad.svg';
import ContentHomeImage from 'assets/InsuresCard/hogar_contenidos.svg';
import LifeImage from 'assets/InsuresCard/vida_individual.svg';
import Truck from 'assets/InsuresCard/truck2.jpg';
export const cards: ICardContent[] = [
  {
    id: 1,
    title: 'Autos',
    disabled: false,
    path: '/app/cotizador/autos',
    image: CarsImage,
    description:
      'Cotiza aquí vehiculos livianos.',
  },
  {
    id: 2,
    title: 'Vehiculos Pesados y Taxis',
    disabled: false,
    path: '/app/cotizador/pesados',
    image: Truck,
    description: 'Cotiza aquí vehiculos pesados, taxis y herramientas de trabajo.',
  },
  {
    id: 3,
    title: 'Motos',
    disabled: false,
    path: '/app/cotizador/motos',
    image: MotorcycleImage,
    description: 'Cotizador de motos individuales.',
  },
  {
    id: 4,
    title: 'Hogar y Vida (deudores)',
    disabled: true,
    path: '/app/cotizador/hogar-vida',
    image: HomeImage,
    description:
      'Cotiza los dos productos en un solo cotizador. Solo aplica para pólizas con beneficiario oneroso. No permite incluir contenidos dentro de la cotización.',
  },
  {
    id: 5,
    title: 'Bicicletas',
    disabled: true,
    path: '/app/cotizador/bicicletas',
    image: BicycleImage,
    description: 'Cotizador de bicicletas individuales.',
  },
  {
    id: 6,
    title: 'Salud',
    disabled: true,
    path: '/app/cotizador/salud',
    image: HealthImage,
    description: 'Cotiza pólizas de salud individual y salud familiar.',
  },
  {
    id: 7,
    title: 'Copropiedad',
    disabled: true,
    path: '/app/cotizador/copropiedad',
    image: CoOwnershipImage,
    description: 'Cotiza aquí pólizas para cualquier tipo de copropiedad a nivel nacional.',
  },
  {
    id: 8,
    title: 'Hogar y contenidos',
    disabled: true,
    path: '/app/cotizador/hogar-contenidos',
    image: ContentHomeImage,
    description:
      'Cotiza aquí pólizas de hogar (sin contenidos) y pólizas de contenidos (sin hogar). Aplica para cualquier tipo de beneficiario (oneroso y no oneroso).',
  },
  {
    id: 9,
    title: 'Vida Individual',
    disabled: true,
    path: '/app/cotizador/vida-individual',
    image: LifeImage,
    description:
      'Cotiza aquí pólizas de vida individuales. No sirve para cotizar póliza de "vida deudores".',
  },
];
