import * as React from 'react';
import DataTable from 'react-data-table-component';
import { Button, TextField, Modal, Backdrop, Snackbar, DialogTitle, DialogContent, DialogActions, Dialog, FormControl, MenuItem, Select, InputLabel } from '@material-ui/core';
import { Add, Edit } from '@material-ui/icons';
import matchSorter from 'match-sorter';
import { TEventTarget } from 'types';
import { useStyles } from './styles';
import Fade from 'components/Admin/AdminUserControlFadeAnimated/AdminUserControlFadeAnimated';
import SignNewUser from 'pages/Admin/SignNewUser/SignNewUser';
import { listAllUsers } from 'services/AdminUsersControlService';
import { formatDate } from 'utils/utils';
import MuiAlert from '@material-ui/lab/Alert';
import { delUser, actUser, statusUser } from 'services/AdminActUser';

const UserControl = () => {
  const classes = useStyles();

  const [filteredOpportunities, setFilteredOpportunities] = React.useState<string>('');
  const [openModal, setOpenModal] = React.useState<boolean>(false);
  const [userCollection, setUserCollection] = React.useState<ICognitoUsers[] | null>(null);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [openSnack, setOpenSnack] = React.useState<boolean>(false);
  const [statusActDes, setStatusActDes] = React.useState<boolean>(true);
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [open, setOpen] = React.useState(false);
  const [openAct, setOpenAct] = React.useState(false);
  const [openDel, setOpenDel] = React.useState(false);
  const [openEdit, setOpenEdit] = React.useState(false);
  const [openEditConf, setOpenEditConf] = React.useState(false);
  const [filterEmail, setFilterEmail] = React.useState<any>('');
  const [tipo, setTipo] = React.useState('');

  const handleChange = (event: any) => {
    setTipo(event.target.value);
  };

  // filter items according the data
  const filteredItems =
    userCollection !== null
      ? matchSorter(userCollection, filteredOpportunities, {
          keys: ['Attributes.3.Value', 'Attributes.5.Value', 'Attributes.6.Value'],
        })
      : [];

  // on Error
  const onError = (error: string) => {
    setOpenSnack(true);
    setErrorMessage(error);
  };

  // launch snackBar
  const onCloseSnackBar = () => {
    setOpenSnack(false);
  };

  // handler filter data table
  const onFilterSearch = (e: TEventTarget) => setFilteredOpportunities(e.target.value);

  const toggleModal = () => setOpenModal(!openModal);

  const onDeleteUser = (email: any) => {
    try {
      delUser(email)
    } catch (error) {
      onError(
        'Ha ocurrido un error el eliminar al usuario'
      );
    }
  };

  const onSubmitActUser = () => {
    try {
      actUser(filterEmail, tipo);
    } catch (error) {
      onError(
        'Ha ocurrido un error al desactivar al usuario'
      );
    }
  };
  
  const onActDesUser = (email: any, status: any) => {
    if(statusActDes === true){
      setStatusActDes(true)
      try {
        statusUser(email, status );
      } catch (error) {
        onError(
          'Ha ocurrido un error al desactivar al usuario'
        );
      }
    }else {
      try {
        statusUser(email, status )
      } catch (error) {
        onError(
          'Ha ocurrido un error al activar al usuario'
        );
      }
    }
  };

  const handleClickOpen = (email: any) => {
    setOpen(true);
    setFilterEmail(email);
  };

  const handleClickOpenAct = (email: any) => {
    setOpenAct(true);
    setFilterEmail(email);
  };

  const handleClickOpenDel = (email: any) => {
    setOpenDel(true);
    setFilterEmail(email);
  };

  const handleClickOpenEdit = (email: any) => {
    setOpenEdit(true);
    setFilterEmail(email);
  };

  const handleClickOpenEditConf = () => {
    setOpenEditConf(true);
  };

  const handleClose = () => {
    setOpen(false);
    setOpenAct(false);
    setOpenDel(false);
    setOpenEdit(false);
    setOpenEditConf(false);
  };

  const columns = [
    {
      name: 'Nombre',
      selector: (row: ICognitoUsers) => row.Attributes[3].Value,
      sortable: true,
    },
    {
      name: 'Email',
      selector: (row: ICognitoUsers) => row.Attributes[6].Value,
      sortable: true,
    },
    {
      name: 'Rol',
      selector: (row: ICognitoUsers) => row.Attributes[5].Value,
      sortable: true,
    },
    {
      name: 'Fecha',
      selector: (row: ICognitoUsers) => formatDate(row.UserCreateDate),
      sortable: true,
    },
    {
      name: 'Estado usuario',
      cell: (row: ICognitoUsers) =>
      row.Enabled ? (
          <Button 
            variant="contained" 
            size="small" 
            color="primary"
            onClick={() => handleClickOpen(row.Attributes[6].Value)}>
              Desactivar
          </Button>
        ) : (
          <Button 
            variant="contained" 
            size="small" 
            color="secondary"
            onClick={() => handleClickOpenAct(row.Attributes[6].Value)}>
              Activar
          </Button>
        ),
    },
    {
      name: 'Eliminar usuario',
      cell: (row: ICognitoUsers) =>
        <Button 
          variant="contained" 
          onClick={() => handleClickOpenDel(row.Attributes[6].Value)}>
            Eliminar
        </Button>
    },
    {
      name: 'Editar usuario',
      cell: (row: ICognitoUsers) => (
        <Button
          variant="contained"
          size="small"
          color="secondary"
          onClick={() => handleClickOpenEdit(row.Attributes[6].Value)}>
            <Edit style={{ color: 'white' }} />
        </Button>
      ),
    },
  ];

  // Document title
  React.useEffect(() => {
    document.title = 'Control de usuarios';

    setLoading(true);
    listAllUsers(onError).then((response) => {
      setUserCollection(response.users.users);
      setLoading(false);
    });
  }, []);

  return (
    <React.Fragment>
      <DataTable
        title="Control de usuarios"
        columns={columns}
        progressPending={loading}
        paginationComponentOptions={{
          rowsPerPageText: 'Filas por página:',
          rangeSeparatorText: 'de',
        }}
        noDataComponent="No se han encontrado registros"
        data={filteredItems}
        defaultSortField="director"
        pagination={true}
        highlightOnHover
        subHeader={true}
        subHeaderComponent={
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <TextField
              id="outlined-basic"
              label="Buscar"
              variant="outlined"
              size="small"
              style={{ margin: '5px' }}
              onChange={onFilterSearch}
            />
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={toggleModal}>
              <Add />
            </Button>
          </div>
        }
        fixedHeaderScrollHeight="500px"
      />
      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        className={classes.modal}
        open={openModal}
        onClose={toggleModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}>
        <Fade in={openModal}>
          <div className={classes.paper}>
            <SignNewUser toggleModal={toggleModal} />
          </div>
        </Fade>
      </Modal>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"¿Estas seguro que quieres DESACTIVAR este usuario?"}</DialogTitle>
        <DialogContent>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => onActDesUser(filterEmail, false)} color="primary">
            Aceptar
          </Button>
          <Button onClick={handleClose} color="primary" autoFocus>
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openAct}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"¿Estas seguro que quieres ACTIVAR este usuario?"}</DialogTitle>
        <DialogContent>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => onActDesUser(filterEmail, true)} color="primary">
            Aceptar
          </Button>
          <Button onClick={handleClose} color="primary" autoFocus>
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openDel}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"¿Estas seguro que quieres ELIMINAR este usuario?"}</DialogTitle>
        <DialogContent>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => onDeleteUser(filterEmail)} color="primary">
            Aceptar
          </Button>
          <Button onClick={handleClose} color="primary" autoFocus>
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openEdit}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Actualizar usuario"}</DialogTitle>
        <DialogContent>
          <FormControl className={classes.formControl}>
            <InputLabel id="demo-simple-select-label">Tipo de usuario</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={tipo}
              onChange={handleChange}
            >
              <MenuItem value={'admin'}>Administrador</MenuItem>
              <MenuItem value={'asesor'}>Asesor</MenuItem>
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClickOpenEditConf} color="primary">
            Aceptar
          </Button>
          <Button onClick={handleClose} color="primary" autoFocus>
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openEditConf}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"¿Estas seguro que quieres EDITAR el rol de este usuario?"}</DialogTitle>
        <DialogContent>
        </DialogContent>
        <DialogActions>
          <Button type="submit" color="primary" onClick={onSubmitActUser}>
            Aceptar
          </Button>
          <Button onClick={handleClose} color="primary" autoFocus>
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar open={openSnack} autoHideDuration={6000} onClose={onCloseSnackBar}>
        <MuiAlert elevation={6} variant="filled" onClose={onCloseSnackBar} severity="error">
          {errorMessage}
        </MuiAlert>
      </Snackbar>
    </React.Fragment>
  );
};

export default UserControl;
