import { HttpRequest } from './HttpRequest';
import { quotationUrl, baseUrl } from 'utils/ApiRoutes';

/**
 * Create quotation
 * @param body object data to do the request
 */
export const createQuotation = async (
  body: IBodyResponseCreateQuoteHome,
  onError: (error: string) => void
): Promise<IQuoteCreationResponseHome> => {
  try {
    const sendQuotation = await new HttpRequest().Post<IQuoteCreationResponseHome>(
      `${baseUrl}${quotationUrl.quotation}`,
      body
    );
    return sendQuotation.data;
  } catch (err) {
    throw onError(
      'Ha ocurrido un error al crear la cotización, por favor revisar la información e intentar de nuevo.'
    );
  }
};

/**
 * Get the resume of quotation
 */
export const getQuotationResume = async (
  typeQuoteWithId: string
): Promise<IQuotationResumeServiceHome> => {
  try {
    const resume = await new HttpRequest().Get<IQuotationResumeServiceHome>(
      `${baseUrl}${quotationUrl.quoteResume}${typeQuoteWithId}`
    );
    return resume.data;
  } catch (err) {
    throw new Error(err);
  }
};

/**
 * Get insure status
 * @param uuid - from quote
 * @param insurerName - name of insurer
 */
export const getInsureStatus = async (
  uuid: string,
  insurerName: string | undefined
): Promise<IGetInsureStatusResponse> => {
  try {
    const stateInsure = await new HttpRequest().Get<IGetInsureStatusResponse>(
      `${baseUrl}${quotationUrl.stateInsurer}${uuid}/${insurerName}`
    );
    return stateInsure.data;
  } catch (err) {
    throw new Error(err);
  }
};

export const resetBotQuotation = async (
  uuid: string,
  insurer: string
): Promise<IResetBotResponse> => {
  try {
    const reset = await new HttpRequest().Get<IResetBotResponse>(
      `${baseUrl}${quotationUrl.resetBotHVD}${uuid}/${insurer}`
    );

    return reset.data;
  } catch (err) {
    throw new Error(err);
  }
};
