import * as React from 'react';
import {
  TableCell,
  CircularProgress,
  Button,
  IconButton,
  Theme,
  withStyles,
  createStyles,
  TableRow,
  Collapse,
  Box,
  TableHead,
  TableBody,
  Table,
  Typography,
} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import InsuranceLogo from 'components/QuotationResume/InsuranceLogo/InsuranceLogo';
import QuoteCorrectStatus from 'assets/QuotationStatus/status_correcto.svg';
import QuoteWrongStatus from 'assets/QuotationStatus/status_error.svg';
import QuoteWarningStatus from 'assets/QuotationStatus/status_alerta.svg';
import { useStyles } from './styles';
import { formatDate } from 'utils/utils';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

const StyledTableRow = withStyles((theme: Theme) =>
  createStyles({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  })
)(TableRow);

const BotStatus: React.FC<IBotStatusProps> = ({ botState, onRetry, onRetryLoading }) => {
  const classes = useStyles();

  if (onRetryLoading) {
    return <CircularProgress />;
  }
  if (botState === 'STORED' || botState === 'WORKING' || botState === undefined) {
    return <CircularProgress />;
  }
  if (botState === 'DATA_ERROR') {
    return <img src={QuoteWarningStatus} width={45} height={45} alt="status" />;
  }
  if (botState === 'ERROR') {
    return (
      <React.Fragment>
        <img src={QuoteWrongStatus} width={45} height={45} alt="status" />
        <Button size="small" variant="contained" className={classes.retryButton} onClick={onRetry}>
          Recotizar
        </Button>
      </React.Fragment>
    );
  }
  if (botState === 'SUCCESS') {
    return <img src={QuoteCorrectStatus} width={45} height={45} alt="status" />;
  }
  return null;
};

const CollapsibleStatus: React.FC<ICollapsibleStatusProps> = ({
  botState,
  open,
  setOpen,
  status,
  insureContext,
}) => {
  const onOpen = () => setOpen(!open);

  if (status?.quotation.results[insureContext].message) {
    return status?.quotation.results[insureContext].message;
  }

  if (botState === 'SUCCESS') {
    return (
      <React.Fragment>
        Ver más...
        <IconButton aria-label="expand row" size="small" onClick={onOpen}>
          {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </IconButton>
      </React.Fragment>
    );
  }
  return <Skeleton animation="wave" />;
};

const RowTable: React.FC<IRowTableProps> = ({
  insure,
  onInsureStatusData,
  onResetBotQuotation,
}) => {
  const insureContext: string = insure !== undefined ? insure : '';
  const [status, setStatus] = React.useState<IGetInsureStatusResponse | null>(null);
  const [currentState, setCurrentState] = React.useState<string>('');
  const [open, setOpen] = React.useState<boolean>(true);
  const [newDetails, setNewDetails] = React.useState<boolean>(false);
  const [detailsDetektor, setDetektor] = React.useState<boolean>(false);

  const [onRetryLoading, setOnRetryLoading] = React.useState<boolean>(false);

  const insureRequest = React.useCallback(
    async (
      onPromiseFuntion: (insure: string | undefined) => Promise<any>
    ): Promise<IGetInsureStatusResponse | null> => {
      let statusPromise = true;
      let result: IGetInsureStatusResponse | null = null;
      do {
        const response = await onPromiseFuntion(insure);
        const botStatus = response.quotation.results[insureContext].state;
        if (botStatus === 'ERROR' || botStatus === 'DATA_ERROR' || botStatus === 'SUCCESS') {
          result = response;
          statusPromise = false;
        }
        await new Promise((resolve) => setTimeout(resolve, 15000));
      } while (statusPromise === true);

      return result;
    },
    [insure, insureContext]
  );

  React.useEffect(() => {
    insureRequest(onInsureStatusData).then((result) => {
      setStatus(result);
    });
  }, [insureRequest, onInsureStatusData]);

  const onRetryQuote = () => {
    setOnRetryLoading(true);
    onResetBotQuotation(insure).then((response) => {
      if (response?.message) {
        insureRequest(onInsureStatusData).then((result) => {
          setStatus(result);
          setOnRetryLoading(false);
        });
      }
    });
  };

  React.useEffect(() => {
    setCurrentState(status?.quotation.results[insureContext].state);

    if (status?.quotation.results[insureContext].quotations) {
      status?.quotation.results[insureContext].quotations.map(
        (quote: IQuotationDetailsForCorrectResponse) => quote.inspection && setNewDetails(true)
      );
    }
    if (status?.quotation.results[insureContext].quotations) {
      status?.quotation.results[insureContext].quotations.map(
        (quote: IQuotationDetailsForCorrectResponse) => quote.safetyDevice && setDetektor(true)
      );
    }
  }, [status, currentState, insure, insureContext]);

  return (
    <React.Fragment>
      <StyledTableRow>
        <TableCell scope="row" align="center">
          <BotStatus
            botState={currentState}
            onRetry={onRetryQuote}
            onRetryLoading={onRetryLoading}
          />
        </TableCell>
        <TableCell align="left">
          {currentState && status?.quotation.results[insureContext].update ? (
            `${formatDate(status?.quotation.results[insureContext].update)}`
          ) : (
            <Skeleton animation="wave" />
          )}
        </TableCell>
        <TableCell align="center">
          <InsuranceLogo insurance={insure} />
        </TableCell>
        <TableCell align="right">
          <CollapsibleStatus
            botState={currentState}
            open={open}
            setOpen={setOpen}
            status={status}
            insureContext={insureContext}
          />
        </TableCell>
      </StyledTableRow>
      <StyledTableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6">Detalle de cotización</Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Producto</TableCell>
                    <TableCell>No. Cotización</TableCell>
                    <TableCell align="right">Precio ($)</TableCell>
                    {newDetails && <TableCell align="right">Inspección</TableCell>}
                    {detailsDetektor && <TableCell align="right">Dipositivo de Seguridad</TableCell>}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {status?.quotation.results[insureContext].quotations
                    ? status?.quotation.results[insureContext].quotations.map(
                        (quotes: IQuotationDetailsForCorrectResponse) => (
                          <TableRow key={quotes.title}>
                            <TableCell component="th" scope="row">
                              {quotes.title}
                            </TableCell>
                            <TableCell>{quotes.nCotizacion}</TableCell>
                            <TableCell align="right">{quotes.value.toLocaleString()}</TableCell>
                            {quotes.inspection && (
                              <TableCell align="right">{quotes.inspection}</TableCell>
                            )}
                            {quotes.safetyDevice && (
                              <TableCell align="right">Este vehículo requiere instalación del dispositivo de seguridad Detektor, recuerde que la instalación no tiene ningún costo pero es obligatoria.</TableCell>
                            )}
                          </TableRow>
                        )
                      )
                    : null}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </StyledTableRow>
    </React.Fragment>
  );
};

export default RowTable;
