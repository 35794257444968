import * as React from 'react';
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import { TableCellProps } from '@material-ui/core/TableCell';
import { Omit } from '@material-ui/types';

import { useStyles } from './styles';
import ThemeColors from 'theme';
import RowTableComponent from 'components/QuotationResumeHome/RowTable/RowTable';

const StyledTableCellHead = styled(
  ({
    bgColor,
    ...other
  }: IStyledTableCellHead & Omit<TableCellProps, keyof IStyledTableCellHead>) => (
    <TableCell {...other} />
  )
)({
  fontSize: 14,
  backgroundColor: (props: IStyledTableCellHead) => props.bgColor && props.bgColor,
  color: 'white',
});

const QuoteResult: React.FC<IQuoteResultPropsHome> = ({
  insuresResults,
  onInsureStatusData,
  onResetBotQuotation,
}) => {
  const classes = useStyles();

  return (
    <>
      <Typography variant="h6" className={classes.title}>
        Datos la cotización
      </Typography>
      <TableContainer component={Paper}>
        <Table aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCellHead bgColor={ThemeColors.palette.primary.main}>
                Estado
              </StyledTableCellHead>
              <StyledTableCellHead bgColor={ThemeColors.palette.primary.main} align="left">
                Fecha
              </StyledTableCellHead>
              <StyledTableCellHead bgColor={ThemeColors.palette.primary.main} align="left">
                Aseguradora
              </StyledTableCellHead>
              <StyledTableCellHead bgColor={ThemeColors.palette.primary.main} align="left">
                Detalle
              </StyledTableCellHead>
            </TableRow>
          </TableHead>
          <TableBody>
            {insuresResults?.selectedInsurers.map((row) => (
              <RowTableComponent
                key={row}
                insure={row}
                onInsureStatusData={onInsureStatusData}
                onResetBotQuotation={onResetBotQuotation}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default QuoteResult;
