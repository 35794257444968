import { useHistory } from 'react-router-dom';
import { useUserDispatch, useInfoUserDispatch } from 'context/UserContext';
import { checkSessionExpiration, signOutForExpiredTime } from '../services/AuthService';
import React, { createContext, ReactNode, useEffect } from 'react';

const AuthContext = createContext<AuthContextType | null>(null);

interface AuthProviderProps {
  children: ReactNode
}

interface AuthContextType {
  validateSessionExpiration: () => Promise<void>;
}

export const AuthProvider: React.FC = ({children}) => {
  const history = useHistory();
  const userDispatch = useUserDispatch(); 
  const infoUserDispatch = useInfoUserDispatch();
  const validateSessionExpiration = async () => {
    const isExpiredToken = await checkSessionExpiration()
    if (isExpiredToken) {
     signOutForExpiredTime({userDispatch, infoUserDispatch, history})
    }
  }

  useEffect(() => {
    validateSessionExpiration();
    const intervalId = setInterval(() => {
      validateSessionExpiration();
    }, 60000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);
  return (
  <AuthContext.Provider value={{validateSessionExpiration}}>
    {children}
  </AuthContext.Provider>
  );
}