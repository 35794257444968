import {
  Snackbar
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React from 'react';

interface NotificationProps {
  open: boolean;
  message: string;
  onClose: () => void;
}
export const Notification: React.FC<NotificationProps> =  ({open, message, onClose}) => {
  return (
    <Snackbar open={open} autoHideDuration={10000} onClose={onClose} key={'TransitionUp'}> 
      <Alert onClose={onClose} severity='warning'>
        {message}
      </Alert>
    </Snackbar>
  );
}