import React, { useState } from 'react';
import {
  CircularProgress,
  Avatar,
  Button,
  TextField,
  Link,
  Box,
  Grid,
  Typography,
  InputAdornment,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom';
import swal from 'sweetalert';
import LogoCavca from 'assets/Branding/cavcaLogoV2.png';
import MailOutlineOutlinedIcon from '@material-ui/icons/MailOutlineOutlined';import { useStyles } from './styles';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Layout from 'components/Auth/Layout';
import { useUserDispatch, useInfoUserDispatch } from 'context/UserContext';
import { IsValidEmail, IsNotEmptyField } from 'utils/utils';
import { loginService, newPasswordRequired } from 'services/AuthService';
import { INVALID_EMAIL, INVALID_PASSWORD } from 'constants/notifyConstants';
import { TEventTarget } from 'types';
import { Notification } from 'components/Global/Notification';
import { fontSize, width } from '@material-ui/system';

export default function SignInSide() {

  const [showSessionExpiredMessage, setShowSessionExpiredMessage] = useState(false);

  // @ts-ignore
  const location = useLocation();
  const searchParams = location.search || (location.state as { from?: { search?: string } })?.from?.search || '';
  const query = new URLSearchParams(searchParams);
  const sessionExpired = query.get('sessionExpired');

  const classes = useStyles();

  // states
  const [emailValue, setEmailValue] = useState<string>('');
  const [passwordValue, setPasswordValue] = useState<string>('');
  const [newPasswordValue, setNewPasswordValue] = useState<string>('');
  const [confirmNewPasswordValue, setConfirmNewPasswordValue] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [challengeName, setChallengeName] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [rememberMe, setRememberMe] = useState<boolean>(false);

  // context
  const userDispatch = useUserDispatch();
  const infoUserDispatch = useInfoUserDispatch();

  // navigation
  const history = useHistory();

  const onLogin = () => {
    if (IsValidEmail(emailValue)) {
      if (IsNotEmptyField(passwordValue)) {
        loginService({
          dispatch: userDispatch,
          roleDispatch: infoUserDispatch,
          email: emailValue,
          password: passwordValue,
          history,
          setIsLoading,
          setPasswordValue,
          setChallengeName,
        }).then(() => {
          if (rememberMe) {
            localStorage.setItem('rememberedEmail', emailValue);
          } else {
            localStorage.removeItem('rememberedEmail');
          }
        })
      } else {
        swal('Oops!', INVALID_PASSWORD.message, 'error');
      }
    } else {
      swal('Oops!', INVALID_EMAIL.message, 'error');
    }
  };

  const onNewPassword = () => {
    if (IsNotEmptyField(newPasswordValue)) {
      newPasswordRequired({
        email: emailValue,
        password: passwordValue,
        newPassword: newPasswordValue,
        setIsLoading,
        history,
        dispatch: userDispatch,
        roleDispatch: infoUserDispatch,
      });
    } else {
      swal('Oops!', INVALID_PASSWORD.message, 'error');
    }
  };

  const handleNotification = () => {
    setShowSessionExpiredMessage(false);
  }

  const validNewPassword = () =>
    newPasswordValue.length > 0 &&
    confirmNewPasswordValue.length > 0 &&
    newPasswordValue === confirmNewPasswordValue;

  // handlers
  const handleEmail = (e: TEventTarget) => {
    setEmailValue(e.target.value);
    setRememberMe(false);
    localStorage.removeItem('rememberedEmail');
  }
  const handlePasswordValue = (e: TEventTarget) => setPasswordValue(e.target.value);
  const handleNewPassword = (e: TEventTarget) => setNewPasswordValue(e.target.value);
  const handleConfirmNewPassword = (e: TEventTarget) => setConfirmNewPasswordValue(e.target.value);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleRememberMe = (e: TEventTarget) => setRememberMe(e.target.checked);

  React.useEffect(() => {
    if(sessionExpired) {
      setShowSessionExpiredMessage(true);
    }
  }, [sessionExpired])
  React.useEffect(() => {
    document.title = 'Iniciar Sesión';
  }, []);
  React.useEffect(() => {
    const savedEmail = localStorage.getItem('rememberedEmail');
    if (savedEmail) {
      setEmailValue(savedEmail);
      setRememberMe(true);
    }
  }, []);


  return (
    <Layout>
      {sessionExpired && (
        <Notification 
        open={showSessionExpiredMessage} 
        message='Por razones de seguridad, hemos cerrado tu sesión. Por favor, inicia sesión nuevamente para continuar.' 
        onClose={handleNotification}
        />
      )}
      <div className={classes.paper}>
      <img className={classes.logotypeIcon} src={LogoCavca} alt="logo" />

        {!challengeName ? (
          <>
            <form className={classes.form} noValidate>
            <TextField
                margin="dense"
                required
                fullWidth
                id="email"
                name="email"
                autoComplete="email"
                autoFocus
                onChange={handleEmail}
                value={emailValue}
                placeholder="Correo"
                InputProps={{
                  startAdornment: (
                    <InputAdornment
                      position="start"
                      style={{
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        color: '#A0A0A0'
                      }}
                    >
                      <MailOutlineOutlinedIcon  style={{ fontSize: '1.5rem' }} />
                    </InputAdornment>
                  ),
                  classes: {
                    underline: classes.underline,
                    root: classes.root
                  },
                  style: {padding: '.5rem'}
                }}
                InputLabelProps={{
                  shrink: true,
                  style: { display: 'none' },
                }}
              />
              
              <TextField
                margin="dense"
                required
                fullWidth
                name="password"
                label="Contraseña"
                type={showPassword ? "text" : "password"}
                placeholder="Contraseña"
                id="password"
                autoComplete="current-password"
                onChange={handlePasswordValue}
                value={passwordValue}
                InputProps={{
                  startAdornment: (
                    <InputAdornment
                      position="start"
                      style={{
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        color: '#A0A0A0'
                      }}
                    >
                      <LockOutlinedIcon  style={{ fontSize: '1.5rem' }} /> {/* Ajusta el tamaño del ícono */}
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment
                    position="end"
                    onClick={handleClickShowPassword}
                    style={{ cursor: 'pointer' }}
                  >
                  {showPassword ? (
                  <Visibility style={{ fontSize: '1rem', color: 'A0A0A0' }} /> 
                  ) : (
                  <VisibilityOff style={{ fontSize: '1rem', color: 'A0A0A0' }} />
                  )}
                  </InputAdornment>
                  ),
                  classes: {
                    underline: classes.underline,
                    root: classes.root
                  },
                  style: {padding: '.5rem'}
                }}
                InputLabelProps={{
                  shrink: true,
                  style: { display: 'none' },
                }}
              />
              <Grid 
              container 
              direction='row' 
              alignItems='center' 
              justifyContent='center'
              spacing={4}
              >
                <Grid item xs={6}>
                  <FormControlLabel
                        control={
                          <Checkbox
                            checked={rememberMe}
                            onChange={handleRememberMe}
                            color="primary"
                            size='small'
                          />
                        }
                        label={
                          <Typography className={classes.txtRemember}>
                            Recordar correo
                          </Typography>
                        }
                  />
                  </Grid>
                <Grid item xs={6} >
                  <Link className= {classes.colorLinkForgotPassword}component={RouterLink} to="/recuperar" variant="body2">
                      <Typography className={classes.txtForgotPassword}>
                        ¿Olvidaste tu contraseña?
                      </Typography> 
                  </Link>
                </Grid>
              </Grid>
              {isLoading ? (
                <CircularProgress size={26} className={classes.loginLoader} />
              ) : (
                <Button
                  
                  variant="contained"
                  className={classes.submit}
                  onClick={onLogin}
                  >
                  Login
                </Button>
              )}
              <Grid container>
                <Grid item xs>
                  <Box textAlign='center' pt={3}>
                    <Typography  className={classes.txtPromo}>
                      La mejor opción para financiar tu tranquilidad
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </form>
          </>
        ) : (
          <>
            <Typography component="h1" variant="h5">
              Crear nueva contraseña pesonal
            </Typography>
            <form className={classes.form} noValidate>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="newPassword"
                label="Nueva Contraseña"
                type="password"
                id="newPassword"
                autoComplete="current-password"
                onChange={handleNewPassword}
                value={newPasswordValue}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="confirmNewPassword"
                label="Confirmar Nueva Contraseña"
                type="password"
                id="confirmNewPassword"
                autoComplete="current-password"
                onChange={handleConfirmNewPassword}
                value={confirmNewPasswordValue}
              />
              {isLoading ? (
                <CircularProgress size={26} className={classes.loginLoader} />
              ) : (
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  onClick={onNewPassword}
                  disabled={!validNewPassword()}>
                  Cambiar Contraseña
                </Button>
              )}
            </form>
          </>
        )}
      </div>
    </Layout>
  );
}
