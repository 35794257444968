import * as React from 'react';

import { Paper, Button, Typography, Grid, Snackbar, CircularProgress, FormControl, MenuItem, Select } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import MuiAlert from '@material-ui/lab/Alert';
import { useHistory, useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import { useStyles } from './styles';
import SubTitle from 'components/HomeQuotation/SubTitle/SubTitle';
import QuoteType from 'components/HomeQuotation/QuoteType/QuoteType';
import {
  insures,
  quoteTypeData,
  documentType,
  occupation,
  defaultValues,
  classEdifice,
  dwellingType,
  ages,
  addressType,
  addressDType,
  addressCType,
} from './resources';
import {
  createQuotation,
} from 'services/QuotationServiceHome';
import { cities } from './cities';
import matchSorter from 'match-sorter';
import { ISelect } from 'types/CarsQuotation/IDataInsure';
import { BootstrapInput } from 'components/Global/PickerSelect/PickerSelect';
import UserDataHome from 'components/HomeQuotation/UserDataHome/UserDataHome';
import UserDataUser from 'components/HomeQuotation/UserDataUser/UserDataUser';
import UserDataVida from 'components/HomeQuotation/UserDataVida/UserDataVida';

const CarsQuotation: React.FC = () => {
  // styles
  const classes = useStyles();
  
  const location = useLocation() as ILocationCarsQuotation;

  // useForm
  const { handleSubmit, control, errors, watch, setValue} = useForm<IHomeQuotation>({
    defaultValues,
  });

  const watchAllFields = watch([
    'checkPets', 
    'checkBoxAddress', 
    'dwellingCategory', 
    'valueDebtLive',
    'valueDwelling',
    'fBorn',
  ]);

  // states
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [openSnack, setOpenSnack] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [typeQuote, setTypeQuote] = React.useState('HogarVidaDeudores');
  const [home, setHome] = React.useState(true);
  const [vida, setVida] = React.useState(true);  

  const handleChangeHome = (event: React.ChangeEvent<HTMLInputElement>) => {
    setHome(event.target.checked);
  };
  
  const handleChangeVida = (event: React.ChangeEvent<HTMLInputElement>) => {
    setVida(event.target.checked);
  };

  const handleChangeTypeQuote = (event: any) => {
    setTypeQuote(event.target.value);
  };

  // on Error
  const onError = (error: string) => {
    setOpenSnack(true);
    setErrorMessage(error);
  };

  // navigation hook
  const history = useHistory();

  // cities search
  const filterCities = (inputValue: string) => {
    const filterCitiesBySearch =
      cities !== null
        ? matchSorter(cities, inputValue, {
            keys: ['value', 'label'],
          })
        : [];
    return filterCitiesBySearch;
  };

  const loadCitiesOptions = (inputValue: string, callback: any) => {
    setTimeout(() => {
      callback(filterCities(inputValue));
    }, 500);
  };

  const handleInputCitiesChange = (newValue: string) => {
    const inputValue = newValue;
    return inputValue;
  };

  // launch snackBar
  const onCloseSnackBar = () => {
    setOpenSnack(false);
  };

  const onCreateQuotation = (bodyObject: any) => {
    createQuotation(bodyObject, onError).then(({ uuid }) => {
      history.push({
        pathname: `/app/cotizador/hogar/resumen/${uuid}`,
      });
      return uuid;
    });
  };

  /**
   * on submit data
   * @param formDataState use form hook object
   */
  const onSubmitQuotationForm = (formDataState: IHomeQuotation) => {

    const newSelectedInsurers: string[] = [];
    let adressComplete;
    let cityLiveRes;
    let cityHomeRes;
    let zonaRes;
    let tipoRes;
    let anoRes;
    let adicionalRes;
    let letraViaRes;
    let letraViaPrincipalRes;
    let n2ViaRes;
    let nViaRes;
    let nViaPrincipalRes;
    let orientacionPrincipalRes;
    let tipoViaRes;

    formDataState.selectedInsurers.map((insure: ISelect) =>
      newSelectedInsurers.push(insure.label)
    );

    if(home === false) {

      cityHomeRes = ["bogota-bogota"]
      zonaRes = 'Sin Zona';
      tipoRes = 'Sin Tipo';
      anoRes = '2016';
      adicionalRes = '';
      letraViaRes = '';
      letraViaPrincipalRes = '';
      n2ViaRes = '';
      nViaRes = '';
      nViaPrincipalRes = '';
      orientacionPrincipalRes = '';
      tipoViaRes = '';

      const aux_insurers = ['SBS_HD', 'MAPFRE_HD', 'LIBERTY_HD', 'ESTADO_HD', 'SURA_HD', 'SURA_HD_N']; 
      for (let index = 0; index < aux_insurers.length; index++) {
        const element = aux_insurers[index];
        const del = newSelectedInsurers.indexOf(element);
        if ( del !== -1) {
          newSelectedInsurers.splice(del, 1)
        }
      }
    }else {
      cityHomeRes = [formDataState.cityHome.value];
      zonaRes = formDataState.dwellingType;
      tipoRes = formDataState.dwellingCategory;
      anoRes = formDataState.ageBuilding;
      adicionalRes = formDataState.adicional;
      letraViaRes = formDataState.letraVia;
      letraViaPrincipalRes = formDataState.letraViaPrincipal;
      n2ViaRes = formDataState.n2Via;
      nViaRes = formDataState.nVia;
      nViaPrincipalRes = formDataState.nViaPrincipal;
      orientacionPrincipalRes = formDataState.orientacionPrincipal;
      tipoViaRes = formDataState.tipoVia;
    }
    
    if(vida === false) {
      cityLiveRes = ["bogota-bogota"];
      const aux_insurers = ['AXA_VD', 'SURA_VD', 'BOLIVAR_VD']; 
      for (let index = 0; index < aux_insurers.length; index++) {
        const element = aux_insurers[index];
        const del = newSelectedInsurers.indexOf(element);
        if ( del !== -1) {
          newSelectedInsurers.splice(del, 1)
        }
      }
    }else {
      if (parseInt(watchAllFields.valueDebtLive) === 0) {
        setOpenSnack(true);
        setErrorMessage('El valor de Saldo de Capital Credito');
      }else {
        cityLiveRes = [formDataState.cityLive.value]
      }
    }
    
    if (watchAllFields.checkBoxAddress === true) {
      const adres = [formDataState.tipoVia, formDataState.nViaPrincipal, formDataState.letraViaPrincipal, formDataState.orientacionPrincipal, formDataState.nVia, formDataState.letraVia, formDataState.orientacionVia, formDataState.n2Via];
      const result = adres.filter(adresOf => adresOf !== undefined);
      adressComplete = result.join(' ');
    } else {
      const aux_insurers = ['SURA_HD_N'];
      for (let index = 0; index < aux_insurers.length; index++) {
        const element = aux_insurers[index];
        const del = newSelectedInsurers.indexOf(element);
        if ( del !== -1) {
          newSelectedInsurers.splice(del, 1)
        }
      }
      adressComplete = formDataState.addressHome
    }

    setLoading(false)
    const bodyObject = {
      ...formDataState,
      selectedInsurers: newSelectedInsurers,
      quoteType: typeQuote,
      nDocument: parseInt(formDataState.nDocument, 10),
      fBorn: formDataState.fBorn,
      cityHome: cityHomeRes,
      cityLive: cityLiveRes,
      nFloors: formDataState.nFloors
       ? parseInt(formDataState.nFloors, 10)
       : 0,
      floorLiveDwells: formDataState.floorLiveDwells
        ? parseInt(formDataState.floorLiveDwells, 10)
        : 0,
      valueDwelling: formDataState.valueDwelling
        ? parseInt(formDataState.valueDwelling, 10)
        : 0,
      valueDebtLive: formDataState.valueDebtLive
        ? parseInt(formDataState.valueDebtLive, 10)
        : 0,
      nPets: formDataState.nPets
        ? parseInt(formDataState.nPets, 10)
        : 0,
      checkPets: formDataState.checkPets
        ? formDataState.checkPets
        : false,
      checkBoxAddress: formDataState.checkBoxAddress
        ? formDataState.checkBoxAddress
        : false,
      nBasement: formDataState.nBasement
        ? parseInt(formDataState.nBasement, 10)
        : 0,
      addressHome: adressComplete
        ? adressComplete
        : '0',
      dwellingType: zonaRes,
      dwellingCategory: tipoRes,
      ageBuilding: anoRes,
      adicional: adicionalRes,
      letraVia: letraViaRes,
      letraViaPrincipal: letraViaPrincipalRes,
      n2Via: n2ViaRes,
      nVia: nViaRes,
      nViaPrincipal: nViaPrincipalRes,
      orientacionPrincipal: orientacionPrincipalRes,
      tipoVia: tipoViaRes,
    };

    onCreateQuotation(bodyObject);
  };

  // header title
  React.useEffect(() => {
    document.title = 'Cotizador - Seguros Hogar y Vida';

    // set hook form value
    if (location.state !== undefined) {
      const quoteByLocation = location.state.quotation;
      const quotationTransform = Object.entries(quoteByLocation);
      quotationTransform.map((quote) => setValue(quote[0], quote[1]));
    }
  }, [location, setValue]);

  return (
    <React.Fragment>
      <form onSubmit={handleSubmit(onSubmitQuotationForm)}>
        <Paper className={classes.paper}>
          <Typography component="h1" variant="h4" align="center" className={classes.title}>
          Cotizador - Seguros Hogar y Vida
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <SubTitle title="Tipo de Cotización" />
            </Grid>
            <QuoteType
              insures={insures}
              control={control}
              quoteType={quoteTypeData}
              disabledInsures={false}
              disabledQuoteType
            />
            <Grid item xs={12} sm={4}>
              <FormControl className={classes.margin} disabled>
                <Typography className={classes.label}>Ramo a Cotizar</Typography>
                <Select
                  labelId="demo-customized-select-label"
                  id="demo-customized-select"
                  value={typeQuote}
                  onChange={handleChangeTypeQuote}
                  input={<BootstrapInput />}>
                  <MenuItem value={'HogarVidaDeudores'}>HogarVidaDeudores</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <SubTitle title="Datos Personales" />
            </Grid>
            <UserDataUser
              control={control}
              documentTypeItems={documentType}
              errors={errors}
            />
            <Grid item xs={1}>
              <Checkbox
                checked={home}
                onChange={handleChangeHome}
                defaultChecked
                color="primary"
              />
            </Grid>
            <Grid item xs={11}>
              <SubTitle title="Datos Hogar" />
            </Grid>
            {home ? (
              <UserDataHome
                control={control}
                classEdificeItems={classEdifice}
                dwellingTypeItems={dwellingType}
                addressTypeItems={addressType}
                addressDTypeItems={addressDType}
                addressCTypeItems={addressCType}
                checkboxPets={watchAllFields.checkPets}
                checkAddress={watchAllFields.checkBoxAddress}
                dwellingCategoryDisable={watchAllFields.dwellingCategory}
                agesItems={ages}
                loadCitiesOptions={loadCitiesOptions}
                handleInputCitiesChange={handleInputCitiesChange}
                errors={errors}
              />
            ) : (
              <Grid item xs={12}>
              </Grid>
            )}
            <Grid item xs={1}>
              <Checkbox
                checked={vida}
                onChange={handleChangeVida}
                defaultChecked
                color="primary"
              />
            </Grid>
            <Grid item xs={11}>
              <SubTitle title="Datos Vida" />
            </Grid>
            {vida ? (
              <UserDataVida
                control={control}
                occupationItems={occupation}
                loadCitiesOptions={loadCitiesOptions}
                handleInputCitiesChange={handleInputCitiesChange}
                errors={errors}
              />
            ) : (
              <Grid item xs={12}>
              </Grid>
            )}
            <Grid item xs={12}>
              <div className={classes.buttons}>
                {loading ? (
                  <CircularProgress color="primary" thickness={4} />
                ) : (
                  home === false && vida === false ? (
                    <h4>Habilite Algun Formulario</h4>
                  ) : (
                    watchAllFields.valueDebtLive === 0 || parseInt(watchAllFields.valueDwelling) === 0 ? (
                      <h4>El valor de Valor Comercial o Saldo de Capital Credito debe ser diferente de '0'</h4>
                    ) : (
                      <Button
                        variant="contained"
                        color="primary"
                        className={classes.button}
                        type="submit"
                        disableElevation>
                        Cotizar
                      </Button>
                    )
                  )
                )}
              </div>
              {Object.keys(errors).length > 0 && (
                <Typography
                  variant="caption"
                  display="block"
                  gutterBottom
                  align="center"
                  style={{ color: 'red' }}>
                  Hay campos incompletos o con errores, por favor revisarlos.
                </Typography>
              )}
            </Grid>
          </Grid>
        </Paper>
        <Snackbar open={openSnack} autoHideDuration={6000} onClose={onCloseSnackBar}>
          <MuiAlert elevation={6} variant="filled" onClose={onCloseSnackBar} severity="error">
            {errorMessage}
          </MuiAlert>
        </Snackbar>
      </form>
    </React.Fragment>
  );
};

export default CarsQuotation;
