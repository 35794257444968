import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Card from '@material-ui/core/Card';
import First from '../../../assets/Avatar/first.png';
import Second from '../../../assets/Avatar/second.png';
import Third from '../../../assets/Avatar/third.png';
import MuiAlert from '@material-ui/lab/Alert';
import Box from '@material-ui/core/Box';
import { formatDate } from 'utils/utils';
import { Snackbar, CircularProgress } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { listRanking } from 'services/AdminUsersControlService';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    rootBox: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'flex-end',
    },
    rootFirst: {
      maxWidth: 345,
      marginLeft: 30,
      marginRight: 30,
    },
    cardFirst: {
      backgroundColor: '#E5E8E8',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      textAlign: 'center',
    },
    cardSecond: {
      backgroundColor: '#E5E8E8',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      textAlign: 'center',
    },
    cardThird: {
      backgroundColor: '#E5E8E8',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      textAlign: 'center',
      justifyContent: 'center',
    },
    rootList: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      textAlign: 'center'
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'center',
      color: theme.palette.text.secondary,
    },
    aling: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    avatar: {
      marginRight: 5,
      backgroundColor: '#3498DB',
    },
    cover: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    box: {
      marginTop: 100,
    },
    title: {
      marginTop: 100,
    },
    cardTitle: {
      marginTop: 10,
    },
    name: {
      marginRight: 5,
    },
    daily: {
      marginLeft: 20,
    },
    first: {
      marginTop: 5,
      width: 220,
    },
    second: {
      marginTop: 5,
      width: 150,
    },
    third: {
      marginTop: 5,
      width: 110,
    },
    card: {
    },
    date: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    table: {
      minWidth: 650,
    },
  }),
);

const Dashboard = () => {
  const classes = useStyles();

  const [loading, setLoading] = React.useState<boolean>(false);
  const [openSnack, setOpenSnack] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [userCotizaciones, setUserCotizaciones] = React.useState<IRanking[] | null>(null);

  // on Error
  const onError = (error: string) => {
    setOpenSnack(true);
    setErrorMessage(error);
  };

  // launch snackBar
  const onCloseSnackBar = () => {
    setOpenSnack(false);
  };

  React.useEffect(() => {
    setLoading(true);
  
    listRanking(onError).then((response) => {
      const dateAct = new Date();
      const monthAct = dateAct.getMonth() + 1;
      switch ( monthAct ) {
        case 1:
          const numRankingE = response.sort((a, b) => {
            if(a.enero > b.enero) {
              return -1;
            }else if(a.enero < b.enero) {
              return 1;
            }else {
              return 0;
            }
          })
          setUserCotizaciones(numRankingE);
          setLoading(false);
          break;
        case 2:
          const numRankingF = response.sort((a, b) => {
            if(a.febrero > b.febrero) {
              return -1;
            }else if(a.febrero < b.febrero) {
              return 1;
            }else {
              return 0;
            }
          })
          setUserCotizaciones(numRankingF);
          setLoading(false);
          break;
        case 3:
          const numRankingM = response.sort((a, b) => {
            if(a.marzo > b.marzo) {
              return -1;
            }else if(a.marzo < b.marzo) {
              return 1;
            }else {
              return 0;
            }
          })
          setUserCotizaciones(numRankingM);
          setLoading(false);
          break;
        case 4:
          const numRankingA = response.sort((a, b) => {
            if(a.abril > b.abril) {
              return -1;
            }else if(a.abril < b.abril) {
              return 1;
            }else {
              return 0;
            }
          })
          setUserCotizaciones(numRankingA);
          setLoading(false);
          break;
        case 5:
          const numRankingMa = response.sort((a, b) => {
            if(a.mayo > b.mayo) {
              return -1;
            }else if(a.mayo < b.mayo) {
              return 1;
            }else {
              return 0;
            }
          })
          setUserCotizaciones(numRankingMa);
          setLoading(false);
          break;
        case 6:
          const numRankingJ = response.sort((a, b) => {
            if(a.junio > b.junio) {
              return -1;
            }else if(a.junio < b.junio) {
              return 1;
            }else {
              return 0;
            }
          })
          setUserCotizaciones(numRankingJ);
          setLoading(false);
          break;
        case 7:
          const numRankingJl = response.sort((a, b) => {
            if(a.julio > b.julio) {
              return -1;
            }else if(a.julio < b.julio) {
              return 1;
            }else {
              return 0;
            }
          })
          setUserCotizaciones(numRankingJl);
          setLoading(false);
          break;
        case 8:
          const result = response.filter(word => word.agosto !== undefined);
          const resultTo = response.filter(word => word.agosto === undefined);
          const numRanking = result.sort((a, b) => {
              if(a.agosto > b.agosto) {
                return -1;
              }else if(a.agosto < b.agosto) {
                return 1;
              }else {
                return 0;
              }
          })
          const object = [...numRanking, ...resultTo]
          setUserCotizaciones(object);
          setLoading(false);
          break;
        case 9:
          const resultS = response.filter(word => word.septiembre !== undefined);
          const resultToS = response.filter(word => word.septiembre === undefined);
          const numRankingS = resultS.sort((a, b) => {
            if(a.septiembre > b.septiembre) {
              return -1;
            }else if(a.septiembre < b.septiembre) {
              return 1;
            }else {
              return 0;
            }
          })
          const objectS = [...numRankingS, ...resultToS]
          setUserCotizaciones(objectS);
          setLoading(false);
          break;
        case 10:
          const resultO = response.filter(word => word.octubre !== undefined);
          const resultToO = response.filter(word => word.octubre === undefined);
          const numRankingO = resultO.sort((a, b) => {
            if(a.octubre > b.octubre) {
              return -1;
            }else if(a.octubre < b.octubre) {
              return 1;
            }else {
              return 0;
            }
          })
          const objectO = [...numRankingO, ...resultToO]
          setUserCotizaciones(objectO);
          setLoading(false);
          break;
        case 11:
          const resultN = response.filter(word => word.noviembre !== undefined);
          const resultToN = response.filter(word => word.noviembre === undefined);
          const numRankingN = resultN.sort((a, b) => {
            if(a.noviembre > b.noviembre) {
              return -1;
            }else if(a.noviembre < b.noviembre) {
              return 1;
            }else {
              return 0;
            }
          })
          const objectN = [...numRankingN, ...resultToN]
          setUserCotizaciones(objectN);
          setLoading(false);
          break;
        case 12:
          const resultD = response.filter(word => word.diciembre !== undefined);
          const resultToD = response.filter(word => word.diciembre === undefined);
          const numRankingD = resultD.sort((a, b) => {
            if(a.diciembre > b.diciembre) {
              return -1;
            }else if(a.diciembre < b.diciembre) {
              return 1;
            }else {
              return 0;
            }
          })
          const objectD = [...numRankingD, ...resultToD]
          setUserCotizaciones(objectD);
          setLoading(false);
          break;
        default: 
          break;
      }
    });
  }, []);

  return (
    <React.Fragment>
    {
    loading ? (
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h4" gutterBottom className={classes.title}>
            Ranking
          </Typography>
        </Grid>
        <Grid item xs={6 }></Grid>
        <Grid item xs={3}>
          <CircularProgress disableShrink />
        </Grid>
        <Grid item xs={12}></Grid>
        <Grid item xs={12}></Grid>
      </Grid>
    ) : (
      userCotizaciones && (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h4" gutterBottom className={classes.title}>
              Ranking
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.rootBox}>
            <Grid item xs={4}>
              <Card className={classes.rootFirst}>
                <CardActionArea className={classes.cardSecond}>
                  <CardMedia
                    className={classes.second}
                    component="img"
                    alt="Contemplative Reptile"
                    height="200"
                    image={Second}
                    title="Contemplative Reptile"
                  />
                  <CardContent className={classes.rootList}>
                    <Avatar className={classes.avatar} src="/static/images/avatar/1.jpg"/>
                    <Typography className={classes.name}>                      
                      <Box fontWeight="fontWeightBold" m={1}>
                        {userCotizaciones[1].userName}
                      </Box>
                    </Typography>
                  </CardContent>
                  <CardContent className={classes.rootList}>
                    <Typography className={classes.name}>                  
                      <Box fontWeight="fontWeightBold" m={1}>
                        Cotizaciones por Mes:
                      </Box>
                    </Typography>
                    <Typography className={classes.daily}>
                      {userCotizaciones[1].noviembre}
                    </Typography>
                  </CardContent>
                  <CardContent className={classes.rootList}>
                    <Typography className={classes.name}>                  
                      <Box fontWeight="fontWeightBold" m={1}>
                        Cotizaciones por Día:
                      </Box>
                    </Typography>
                    <Typography className={classes.daily}>    
                        {userCotizaciones[1].daily}
                    </Typography>
                  </CardContent>
                  <CardContent className={classes.rootList}>
                    <Typography className={classes.name}>                  
                      <Box fontWeight="fontWeightBold" m={1}>
                        {userCotizaciones[1].email}    
                      </Box>                
                    </Typography>
                  </CardContent>
                  <CardContent className={classes.rootList}>
                    <Typography>              
                      <Box fontWeight="fontWeightBold" m={1}>
                        {formatDate(userCotizaciones[1].updatedAt)}  
                      </Box>                  
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
            <Grid item xs={4}>
              <Card className={classes.rootFirst}>
                <CardActionArea className={classes.cardFirst}>
                  <CardMedia
                    className={classes.first}
                    component="img"
                    alt="Contemplative Reptile"
                    height="250"
                    image={First}
                    title="Contemplative Reptile"
                  />
                  <CardContent className={classes.rootList}>
                    <Avatar className={classes.avatar} src="/static/images/avatar/1.jpg"/>
                    <Typography className={classes.name}>                      
                      <Box fontWeight="fontWeightBold" m={1}>
                        {userCotizaciones[0].userName}
                      </Box>
                    </Typography>
                  </CardContent>
                  <CardContent className={classes.rootList}>
                    <Typography className={classes.name}>                  
                      <Box fontWeight="fontWeightBold" m={1}>
                        Cotizaciones por Mes:
                      </Box>
                    </Typography>
                    <Typography className={classes.daily}>
                      {userCotizaciones[0].noviembre}
                    </Typography>
                  </CardContent>
                  <CardContent className={classes.rootList}>
                    <Typography className={classes.name}>                  
                      <Box fontWeight="fontWeightBold" m={1}>
                        Cotizaciones por Día:
                      </Box>
                    </Typography>
                    <Typography className={classes.daily}>    
                        {userCotizaciones[0].daily}
                    </Typography>
                  </CardContent>
                  <CardContent className={classes.rootList}>
                    <Typography className={classes.name}>                  
                      <Box fontWeight="fontWeightBold" m={1}>
                        {userCotizaciones[0].email}    
                      </Box>                
                    </Typography>
                  </CardContent>
                  <CardContent className={classes.rootList}>
                    <Typography>              
                      <Box fontWeight="fontWeightBold" m={1}>
                        {formatDate(userCotizaciones[0].updatedAt)}  
                      </Box>                  
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
            <Grid item xs={4}>
              <Card className={classes.rootFirst}>
                <CardActionArea className={classes.cardThird}>
                  <CardMedia
                    className={classes.third}
                    component="img"
                    alt="Contemplative Reptile"
                    height="150"
                    image={Third}
                    title="Contemplative Reptile"
                  />
                  <CardContent className={classes.rootList}>
                    <Avatar className={classes.avatar} src="/static/images/avatar/1.jpg"/>
                    <Typography className={classes.name}>                      
                      <Box fontWeight="fontWeightBold" m={1}>
                        {userCotizaciones[2].userName}
                      </Box>
                    </Typography>
                  </CardContent>
                  <CardContent className={classes.rootList}>
                    <Typography className={classes.name}>                  
                      <Box fontWeight="fontWeightBold" m={1}>
                        Cotizaciones por Mes:
                      </Box>
                    </Typography>
                    <Typography className={classes.daily}>
                      {userCotizaciones[2].noviembre}
                    </Typography>
                  </CardContent>
                  <CardContent className={classes.rootList}>
                    <Typography className={classes.name}>                  
                      <Box fontWeight="fontWeightBold" m={1}>
                        Cotizaciones por Día:
                      </Box>
                    </Typography>
                    <Typography className={classes.daily}>    
                        {userCotizaciones[2].daily}
                    </Typography>
                  </CardContent>
                  <CardContent className={classes.rootList}>
                    <Typography className={classes.name}>                  
                      <Box fontWeight="fontWeightBold" m={1}>
                        {userCotizaciones[2].email}    
                      </Box>                
                    </Typography>
                  </CardContent>
                  <CardContent className={classes.rootList}>
                    <Typography>              
                      <Box fontWeight="fontWeightBold" m={1}>
                        {formatDate(userCotizaciones[2].updatedAt)}  
                      </Box>                  
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          </Grid>
          <Grid item xs={12} >
              <Card className={classes.card}>
                <CardContent className={classes.rootList}>
                  <Grid item xs={1}>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography >
                      <Box fontWeight="fontWeightBold" m={1}>
                        Nombre
                      </Box>                  
                    </Typography>
                  </Grid>
                  <Grid item xs={3} >
                    <Typography >
                      <Box fontWeight="fontWeightBold" m={1}>
                        Correo
                      </Box>  
                    </Typography>
                  </Grid>
                  <Grid item xs={2} >
                    <Typography >
                      <Box fontWeight="fontWeightBold" m={1}>
                        Cotizaciones por Día
                      </Box>  
                    </Typography>
                  </Grid>
                  <Grid item xs={3} >
                    <Typography >
                      <Box fontWeight="fontWeightBold" m={1}>
                        Fecha Última Cotización
                      </Box>  
                    </Typography>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          {userCotizaciones && (
            userCotizaciones.map((row) => (
              <Grid item xs={12} >
                <Card className={classes.card}>
                  <CardContent className={classes.rootList}>
                    <Grid item xs={1}>
                      <Avatar className={classes.avatar} src="/static/images/avatar/1.jpg"/>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography >
                        {row.userName}
                      </Typography>
                    </Grid>
                    <Grid item xs={3} >
                      <Typography >
                        {row.email}
                      </Typography>
                    </Grid>
                    <Grid item xs={2} >
                      <Typography >
                        {row.daily}
                      </Typography>
                    </Grid>
                    <Grid item xs={3} >
                      <Typography >
                        {formatDate(row.updatedAt)}
                      </Typography>
                    </Grid>
                  </CardContent>
                  <CardContent className={classes.rootList}>
                    <Grid item xs={1}>
                      <Typography className={classes.date} variant="caption" display="block" gutterBottom>
                        <Box fontWeight="fontWeightBold" m={1}>
                        Enero:
                        </Box>    
                        0
                      </Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <Typography className={classes.date} variant="caption" display="block" gutterBottom>
                        <Box fontWeight="fontWeightBold" m={1}>
                        Febrero:
                        </Box>    
                        0
                      </Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <Typography className={classes.date} variant="caption" display="block" gutterBottom>
                        <Box fontWeight="fontWeightBold" m={1}>
                        Marzo:
                        </Box>    
                        0
                      </Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <Typography className={classes.date} variant="caption" display="block" gutterBottom>
                        <Box fontWeight="fontWeightBold" m={1}>
                        Abril:
                        </Box>    
                        0
                      </Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <Typography className={classes.date} variant="caption" display="block" gutterBottom>
                        <Box fontWeight="fontWeightBold" m={1}>
                        Mayo:
                        </Box>    
                        0
                      </Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <Typography className={classes.date} variant="caption" display="block" gutterBottom>
                        <Box fontWeight="fontWeightBold" m={1}>
                        Junio:
                        </Box>    
                        0
                      </Typography>
                    </Grid>
                    {row.julio > 0 ? (
                      <Grid item xs={1}>
                        <Typography className={classes.date} variant="caption" display="block" gutterBottom>
                          <Box fontWeight="fontWeightBold" m={1}>
                          Julio:
                          </Box>    
                          {row.julio}
                        </Typography>
                      </Grid>
                    ) : (
                      <Grid item xs={1}>
                        <Typography >
                          Julio: 0
                        </Typography>
                      </Grid>
                    )}
                    {row.agosto > 0 ? (
                      <Grid item xs={1}>
                        <Typography className={classes.date} variant="caption" display="block" gutterBottom>
                          <Box fontWeight="fontWeightBold" m={1}>
                            Agosto:
                          </Box>    
                            {row.agosto}
                        </Typography>
                      </Grid>
                    ) : (
                      <Grid item xs={1}>
                        <Typography className={classes.date} variant="caption" display="block" gutterBottom>
                          <Box fontWeight="fontWeightBold" m={1}>
                            Agosto:
                          </Box>    
                            0
                        </Typography>
                      </Grid>
                    )}
                    {row.septiembre > 0 ? (
                      <Grid item xs={1}>
                        <Typography className={classes.date} variant="caption" display="block" gutterBottom>
                          <Box fontWeight="fontWeightBold" m={1}>
                            Septiembre: 
                          </Box>   
                          {row.septiembre}
                        </Typography>
                      </Grid>
                    ) : (
                      <Grid item xs={1}>
                      <Typography className={classes.date} variant="caption" display="block" gutterBottom>
                        <Box fontWeight="fontWeightBold" m={1}>
                        Septiembre:
                        </Box>    
                        0
                      </Typography>
                      </Grid>
                    )}
                    {row.octubre > 0 ? (
                      <Grid item xs={1}>
                        <Typography className={classes.date} variant="caption" display="block" gutterBottom>
                          <Box fontWeight="fontWeightBold" m={1}>
                            Octubre: 
                          </Box>   
                          {row.octubre}
                        </Typography>
                      </Grid>
                    ) : (
                      <Grid item xs={1}>
                      <Typography className={classes.date} variant="caption" display="block" gutterBottom>
                        <Box fontWeight="fontWeightBold" m={1}>
                        Octubre:
                        </Box>    
                        0
                      </Typography>
                      </Grid>
                    )}
                    {row.noviembre > 0 ? (
                      <Grid item xs={1}>
                        <Typography className={classes.date} variant="caption" display="block" gutterBottom>
                          <Box fontWeight="fontWeightBold" m={1}>
                            Noviembre: 
                          </Box>   
                          {row.noviembre}
                        </Typography>
                      </Grid>
                    ) : (
                      <Grid item xs={1}>
                      <Typography className={classes.date} variant="caption" display="block" gutterBottom>
                        <Box fontWeight="fontWeightBold" m={1}>
                        Noviembre:
                        </Box>    
                        0
                      </Typography>
                      </Grid>
                    )}
                    {row.diciembre > 0 ? (
                      <Grid item xs={1}>
                        <Typography className={classes.date} variant="caption" display="block" gutterBottom>
                          <Box fontWeight="fontWeightBold" m={1}>
                            Diciembre: 
                          </Box>   
                          {row.diciembre}
                        </Typography>
                      </Grid>
                    ) : (
                      <Grid item xs={1}>
                      <Typography className={classes.date} variant="caption" display="block" gutterBottom>
                        <Box fontWeight="fontWeightBold" m={1}>
                        Diciembre:
                        </Box>    
                        0
                      </Typography>
                      </Grid>
                    )}
                  </CardContent>
                </Card>
              </Grid>
            )
          ))}
        </Grid>
        )
        )}
        <Snackbar open={openSnack} autoHideDuration={6000} onClose={onCloseSnackBar}>
          <MuiAlert elevation={6} variant="filled" onClose={onCloseSnackBar} severity="error">
            {errorMessage}
          </MuiAlert>
        </Snackbar>
    </React.Fragment>
  );
};

export default Dashboard;