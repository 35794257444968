import * as React from 'react';
import { AppBar, Toolbar, IconButton, Typography } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';

import { signOut } from 'services/AuthService';
import { useStyles } from './styles';
import SplitMenu from './SplitMenu/SplitMenu';
import { useUserDispatch, useInfoUserDispatch } from 'context/UserContext';
import { useLayoutState, toggleSidebar, useLayoutDispatch } from 'context/LayoutContext';
import LogoCavca from 'assets/Branding/cavcaLogoV2White.png';

const Header: React.FC<IHeaderWithDrawer> = ({ drawerVisible }) => {
  const classes = useStyles();

  // navigation
  const history = useHistory();

  // context
  const { isSidebarOpened } = useLayoutState();
  const layoutDispatch = useLayoutDispatch();
  const userDispatch = useUserDispatch();
  const infoUserDispatch = useInfoUserDispatch();

  // log out
  const onSignOut = () => {
    signOut({ userDispatch, infoUserDispatch, history });
  };

  /**
   * Open drawer
   */
  const handleDrawerOpen = () => {
    toggleSidebar(layoutDispatch);
  };

  return (
    <AppBar
      position="absolute"
      className={clsx(classes.appBar, drawerVisible && isSidebarOpened && classes.appBarShift)}>
      <Toolbar className={classes.toolbar}>
        {drawerVisible && (
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(classes.menuButton, isSidebarOpened && classes.menuButtonHidden)}>
            <MenuIcon />
          </IconButton>
        )}
        <img className={classes.logo} src={LogoCavca} alt="logo" />
        <div style={{ flexGrow: 1 }} />
        <SplitMenu onClick={onSignOut} />
      </Toolbar>
    </AppBar>
  );
};

export default Header;
