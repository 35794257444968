import { HttpRequest } from './HttpRequest';
import { quotationUrl, baseUrl } from 'utils/ApiRoutes';

/**
 * Create quotation
 * @param body object data to do the request
 */
export const createQuotation = async (
    body: IBodyResponseCreateQuoteCars,
    onError: (error: string) => void
): Promise<IQuoteCreationResponse> => {
    try {
        const sendQuotation = await new HttpRequest().Post<IQuoteCreationResponse>(
            `${baseUrl}${quotationUrl.quotation}`,
            body
        );
        return sendQuotation.data;
    } catch (err) {
        throw onError(
            'Ha ocurrido un error al crear la cotización, por favor revisar la información e intentar de nuevo.'
        );
    }
};

/**
 * Get fasecolda code using license plate
 */
export const getFasecoldaCode = async (
    licensePlate: string,
    onError: (error: string) => void
): Promise<IFasecoldaCodeResponse> => {
    try {
        const fasecolda = await new HttpRequest().Get<IFasecoldaCodeResponse>(
            `${baseUrl}${quotationUrl.fasecoldaCode}${licensePlate}`
        );
        return fasecolda.data;
    } catch (err) {
        throw onError(
            'Lo sentimos nos es imposible encontrar los datos de tu placa, intenta ingresando el fasecolda y el modelo de forma manual.'
        );
    }
};

/**
 * Get the resume of quotation
 */
export const getQuotationResume = async (
    typeQuoteWithId: string
): Promise<IQuotationResumeService> => {
    try {
        const resume = await new HttpRequest().Get<IQuotationResumeService>(
            `${baseUrl}${quotationUrl.quoteResume}${typeQuoteWithId}`
        );
        return resume.data;
    } catch (err) {
        throw new Error(err);
    }
};

/**
 * Get insure status
 * @param uuid - from quote
 * @param insurerName - name of insurer
 */
export const getInsureStatus = async (
    uuid: string,
    insurerName: string | undefined
): Promise<IGetInsureStatusResponse> => {
    try {
        const stateInsure = await new HttpRequest().Get<IGetInsureStatusResponse>(
            `${baseUrl}${quotationUrl.stateInsurer}${uuid}/${insurerName}`
        );
        return stateInsure.data;
    } catch (err) {
        throw new Error(err);
    }
};

/**
 * Validates if a quotation already exists
 * @param licensePlate - vehicle license plate
 */
export const getValidateQuotation = async (
    licensePlate: string,
    onError: (error: string) => void
): Promise<IValidateQuotationResponse> => {
    try {
        const validation = await new HttpRequest().Get<IValidateQuotationResponse>(
            `${baseUrl}${quotationUrl.validateQuotation}${licensePlate}`
        );

        return validation.data;
    } catch (err) {
        throw onError(err.response.data.message);
    }
};

/**
 * Get refs with fasecolda
 * @param fasecolda
 */
export const getRefFasecolda = async (
    fasecolda: string | undefined,
    onError: (error: string) => void
): Promise<IRefFasecoldaResponse> => {
    try {
        const refs = await new HttpRequest().Get<IRefFasecoldaResponse>(
            `${baseUrl}${quotationUrl.refFasecolda}${fasecolda}`
        );

        return refs.data;
    } catch (err) {
        throw onError(err.response.data.message);
    }
};

export const resetBotQuotation = async (
    uuid: string,
    insurer: string
): Promise<IResetBotResponse> => {
    try {
        const reset = await new HttpRequest().Get<IResetBotResponse>(
            `https://k96dmtek9e.execute-api.us-east-2.amazonaws.com/dev${quotationUrl.resetBotMoto}${uuid}/${insurer}`
        );

        return reset.data;
    } catch (err) {
        throw new Error(err);
    }
};
