import * as React from 'react';
import { Button, TextField, Snackbar} from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import matchSorter from 'match-sorter';
import Avatar from '@material-ui/core/Avatar';
import MuiAlert from '@material-ui/lab/Alert';
import DataTable from 'react-data-table-component';
import { formatDate } from 'utils/utils';
import { TEventTarget } from 'types';
import { listAllExcel, downloadExcel } from 'services/AdminUsersControlService';
import ExcelLogo from '../../../assets/Avatar/excel.png'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    avatar: {
      marginLeft: 100,
    },
  }),
);

const DownloadExcel = () => {
  const classes = useStyles();

  const [openSnack, setOpenSnack] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [loading, setLoading] = React.useState<boolean>(false);
  const [filteredOpportunities, setFilteredOpportunities] = React.useState<string>('');
  const [userCotizaciones, setUserCotizaciones] = React.useState<IExcel[] | null>(null);

  const filteredItems =
    userCotizaciones !== null
      ? matchSorter(userCotizaciones, filteredOpportunities, {
          keys: ['Key', 'LastModified'],
        })
      : [];

  const onFilterSearch = (e: TEventTarget) => setFilteredOpportunities(e.target.value);
  
  const onError = (error: string) => {
    setOpenSnack(true);
    setErrorMessage(error);
  };

  const onCloseSnackBar = () => {
    setOpenSnack(false);
  };

  const downloadExcels = (Key: any) => {
    setLoading(true)
    
    downloadExcel(Key, onError).then((response) => {
      window.open(response.urlDowload);
      setLoading(false);
    });
  }
  
  const columnsCotizaciones = [
    {
      name: '',
      selector: (row: IExcel) => (
        <Avatar className={classes.avatar} src={ExcelLogo}/>
      ),
      sortable: true,
    },
    {
      name: 'Nombre del Archivo',
      selector: (row: IExcel) => row.Key,
      sortable: true,
    },
    {
      name: 'Ultima Actualizacion',
      selector: (row: IExcel) => formatDate(row.LastModified),
      sortable: true,
    },
    {
      name: '',
      selector: (row: IExcel) => (
        <Button
          variant="contained"
          size="small"
          color="primary"
          onClick={() => downloadExcels(row.Key)}>
          DESCARGAR
        </Button>
      ), 
    },
  ];

  React.useEffect(() => {
    setLoading(true);
    listAllExcel(onError).then((response) => {
      setUserCotizaciones(response);
      setLoading(false);
    });
  }, []);

  return (
    <React.Fragment>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <DataTable
            title="Reporte Cotizaciones Mensuales"
            columns={columnsCotizaciones}
            progressPending={loading}
            paginationComponentOptions={{
              rowsPerPageText: 'Filas por página:',
              rangeSeparatorText: 'de',
            }}
            noDataComponent="No se han encontrado reportes"
            data={filteredItems}
            defaultSortField="year"
            pagination={true}
            highlightOnHover
            subHeader={true}
            subHeaderComponent={
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <TextField
                  id="outlined-basic"
                  label="Buscar"
                  variant="outlined"
                  size="small"
                  style={{ margin: '5px' }}
                  onChange={onFilterSearch}
                />
              </div>
            }
            fixedHeaderScrollHeight="700px"
          />
        </Grid>
      </Grid>
      <Snackbar open={openSnack} autoHideDuration={6000} onClose={onCloseSnackBar}>
        <MuiAlert elevation={6} variant="filled" onClose={onCloseSnackBar} severity="error">
          {errorMessage}
        </MuiAlert>
      </Snackbar>
    </React.Fragment>
  );
};

export default DownloadExcel;