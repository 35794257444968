import { makeStyles } from '@material-ui/styles';
import { Theme } from '@material-ui/core';
import AuthImage from 'assets/Auth/auth_2.jpg';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: `url(${AuthImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  formContainer: {
    maxWidth: '450px',
    maxHeight: '450px',
    padding: theme.spacing(4),
    backgroundColor: 'rgba(255, 255, 255, 1)',
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[5],
  },
  form: {},
}));
